import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '../../../components/Button';
import PageTop from '../../../components/PageTop';
import apiSelf from '../../../services/api-self';
import { decryptPassword } from '../../../utils/hash';

const SelfInstallationReport = () => {
  const [fullLoading, setFullLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [error, setError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [logoLines, setLogoLines] = useState({});
  const [invoiceDate, setInvoiceDate] = useState('');
  const [leadId, setLeadId] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const session = localStorage.getItem('userId');
  const selfId = localStorage.getItem('selfId');
  const [selfData, setSelfData] = useState('');

  useEffect(() => {
    const lead_id = localStorage.getItem('leadId');
    const self_data = localStorage.getItem('selfData');
    const invoice_time = localStorage.getItem('selfInvoiceTime');
    const new_logo_lines = localStorage.getItem('newLogoLines');
    const self_departments = localStorage.getItem('selfDepartments');
    
    if(lead_id) setLeadId(lead_id);
    if(invoice_time) setInvoiceDate(formatInvoiceTime(invoice_time));
    if(new_logo_lines) setLogoLines(JSON.parse(new_logo_lines));
    if(self_departments) setDepartments(JSON.parse(self_departments));
    if(self_data) setSelfData(JSON.parse(self_data));
  }, []);

  const refreshSession = async () => {
    const username = localStorage.getItem('username');
    const encryptedPassword = localStorage.getItem('password');
    
    if (!username || !encryptedPassword) {
      console.log('Kullanıcı adı veya şifre bulunamadı');
      localStorage.removeItem('userId');
      navigate("/giris-yap", { state: { from: location, selfId: selfId } });
      return null;
    }

    try {
      const password = decryptPassword(encryptedPassword);
      const response = await apiSelf.login({
        user_name: username,
        password: password
      });
      
      if (response?.error?.number === '10') {
        console.log('Hata refreshSession', response.error.message);
        localStorage.removeItem('userId');
        return null;
      } else if (response?.error?.number === '0') {
        localStorage.setItem('userId', response.id);
        console.log('Session yenilendi');
        return response.id;
      }
    } catch (error) {
      console.log('Session yenilenirken hata', error.message);
      localStorage.removeItem('userId');
    }
    
    return null;
  };

  const setLogoDetail = async () => {
    setFullLoading(true);
    setError('');
    const newDepartments = JSON.parse(localStorage.getItem('selfDepartments'));
    const newDepartmentsData = {
        department: newDepartments.map(item => ({
          name: item.name || "",
          rate: String(item.kdv) || ""
        }))
    };
    const lead_encoded_id = leadId;
    let retryCount = 0;

    const fetchData = async () => {
        try {
            const response = await apiSelf.set_logo_detail({
                session,
                rel_id: selfData.rel_id,
                type: selfData.type,
                logo_line_1: logoLines.logoLineOne,
                logo_line_2: logoLines.logoLineTwo,
                logo_line_3: logoLines.logoLineThree,
                logo_line_4: logoLines.logoLineFour,
                logo_line_5: logoLines.logoLineFive,
                logo_line_6: logoLines.logoLineSix,
                logo_line_7: logoLines.logoLineSeven,
                departments: newDepartmentsData
            });
            console.log('response', response);
            localStorage.setItem('selfResponse', JSON.stringify(response));
            if(response.error?.number === '0'){
                console.log('hata yok');
                return true;
            } else {
                setError('Bir hata oluştu');
                console.log('error setLogoDetail', response.error);
                if (response.error.number === '10') {
                    throw new Error('Invalid session');
                } else if(response.error.number === '51'){
                    setError('Kayıt bulunmadı.');
                } else {
                    setError('set logo detay' + ' ' + response.error.description);
                }
                return false;
            }
        } catch (error) {
            console.error('api error:', error);
            if (error.message === 'Invalid session' && retryCount < 3) {
                retryCount++;
                console.log('Oturum yenileniyor...');
                const newSession = await refreshSession();
                if (newSession) {
                    return fetchData();
                } else {
                    throw new Error('Oturum yenilenemedi');
                }
            }
            throw error;
        }
    };

    try {
        const result = await fetchData();
        return result;
    } catch (error) {
        console.error('Veri gönderme hatası:', error);
        setError('Veri gönderilemedi. Lütfen daha sonra tekrar deneyin.');
        return false;
    } finally {
        setFullLoading(false);
    }
  };

  const handleNavigate = async () => {
    setFullLoading(true);
    try {
      const setLogoDetailResponse = await setLogoDetail();
      if(setLogoDetailResponse === true){
        ['selfData', 'selfDepartments', 'selfId', 'selfInvoiceTime', 'selfResponse', 'selfSetup', 'selfInitialDepartments']
          .forEach(key => localStorage.removeItem(key));
        const prefix = location.pathname.startsWith('/f') ? 'f' : 'l';
        navigate(`/${prefix}/${selfId}/basarili`, {replace: true});
      } else {
        setErrorMessage('Bir hata oluştu')
      }
    } catch (error) {
      console.error('Navigation error:', error);
      setErrorMessage('Bir hata oluştu: ' + error.message);
    } finally {
      setFullLoading(false);
    }
  };

  function formatInvoiceTime(invoiceTimeString) {
    const [datePart, timePart] = invoiceTimeString.split(' ');
    const [day, month, year] = datePart.split('-');
    return `${day}.${month}.${year}, ${timePart}`;
  }

  return (
    <div className='page-wrapper container'>
      <div className='page-top'>
          <PageTop 
              backBtnValue={"Geri"}
              title={"Ayarlar"}
          />
          <div className='page-content'>
            {fullLoading && <div className='loading-full'></div>}
            <div className='installation-report'>
              <div className='wrapper'>
                <div className='report-top-section'>
                  <div className='row'>
                    <div className='col'>
                      Tarih / Saat :
                    </div>
                    <div className='col text-end'>
                      {invoiceDate}
                    </div>
                  </div>
                </div>
                <hr />
                <div className='text-center infos'>
                    <h6 className='mb-0 mini-title'>Fiş Başlığı</h6>
                    <div>
                      {Object.values(logoLines).slice(0, 5).map((line, index) => (
                        <p key={index} className='mb-0'>{line}</p>
                      ))}
                      <div style={{ display: 'flex', justifyContent: 'center', gap: '.5rem' }}>
                        <p className='mb-0'>{logoLines.logoLineSix}</p>
                        <p className='mb-0'>{logoLines.logoLineSeven}</p>
                      </div>
                    </div>
                </div>
                <hr />
                <div>
                  <h6 className='text-center mini-title'>Departmanlar</h6>
                  {departments.map((department, index) => (
                    <div className='row' key={index}>
                      <div className='col'>
                        {department.name}
                      </div>
                      <div className='col text-end'>
                        KDV %{department.kdv}
                      </div>
                    </div>
                  ))}
                </div>
                <hr />
              </div>
            </div>
            {errorMessage && <div className='error-message mt-3'>{errorMessage}</div>}
          </div>
      </div>
      <div className='page-bottom'>
        <div className='btn-wrapper'>
            <Button 
                value="Kaydet" 
                viewType="btn-blue"
                onClick={handleNavigate} 
            />
        </div>
      </div>
    </div>
  );
};

export default SelfInstallationReport;