import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '../../../components/Button';
import SingleDepartment from '../../../components/SingleDepartment';
import PageTop from '../../../components/PageTop';
import { toAdvancedTitleCase, refreshSession } from '../../../utils/function.js';
import posCihazi from '../../../assets/images/pos-cihazi.jpg';

const SelfDepartments = () => {
  const [fullLoading, setFullLoading] = useState(true);
  const [departments, setDepartments] = useState([]);
  const [selectedDept, setSelectedDept] = useState(null);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [showWarning, setShowWarning] = useState(false);
  const [leadId, setLeadId] = useState('');
  const errorRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const lead_id = localStorage.getItem('selfId')
    if(lead_id){
      setLeadId(lead_id);
    }
    setFullLoading(false);
  }, []);

  useEffect( () => {
    const storedDepartments = localStorage.getItem('selfDepartments') || '';
    if (storedDepartments) {
      const parsedDepartments = JSON.parse(storedDepartments);
      const departmentsWithPercentage = parsedDepartments.map(dept => ({
        ...dept,
        kdv: `%${dept.kdv}`,
        allowedVats: dept.allowedVats || "20"
      }));
      setDepartments(departmentsWithPercentage);
    } else {
        let initialDepartments = [];
        const selfData = JSON.parse(localStorage.getItem('selfData'));
        const departments = selfData.get_department_list.item;

        if (selfData.get_department_list && selfData.get_department_list.item) {

          selfData.get_department_list.item.forEach(dept => {
            if (dept.name && dept.name.trim() !== '') {
              const kdv = `%${dept.rate}`;
  
              const department = {
                name: dept.name,
                kdv: kdv ? kdv : '%20',
                allowedVats: ['0','1','10','20']
              };
              initialDepartments.push(department);
              setDepartments(prev => [...prev, department]);
            }
          });

          localStorage.setItem('selfInitialDepartments', JSON.stringify(initialDepartments));
        } else {
          console.error('NACE faaliyet kodları yüklenemedi veya gib_info tanımsız.');
        }
      }
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        handleAddDepartment();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [departments]); 

  const handleClick = (index) => {
    setSelectedDept(index); 
    setError('');
    setShowWarning(true);
  };

  useEffect(() => {
    const clickedExists = document.querySelector('.single-department-item.clicked') !== null;
    setShowWarning(clickedExists);
  }, [departments]);

  
  const handleNavigate = () => {
    if (departments.length > 8) {
      setError('En fazla 8 adet departman tanımlayabilirsiniz.');
      return; 
    };
    
    const allFieldsFilled = departments.every(dept => dept.name.trim() && dept.kdv.trim() && dept.kdv !== '%');
    if (!allFieldsFilled) {
      setError('Lütfen tüm departmanlar için isim ve KDV değerlerini doldurunuz. Tüm KDV alanları dolu olmalıdır.');
      return;
    }
    if (allFieldsFilled && departments.length > 0) {
      const departmentsToSave = departments.map(({ name, kdv, allowedVats }) => ({
        name,
        kdv: parseInt(kdv.replace('%', ''), 10),
        allowedVats
      }));
      localStorage.setItem('selfDepartments', JSON.stringify(departmentsToSave));

      const initialDepartments = JSON.parse(localStorage.getItem('selfInitialDepartments') || '[]');
      const normalizedInitialDepartments = initialDepartments.map(dept => ({
        ...dept,
        kdv: parseInt(dept.kdv.replace('%', ''), 10)
      }));

      const currentDepartmentsForComparison = departments.map(({ name, kdv, allowedVats }) => ({
        name,
        kdv: parseInt(kdv.replace('%', ''), 10),
        allowedVats
      }));

      const isDifferent = JSON.stringify(normalizedInitialDepartments) !== JSON.stringify(currentDepartmentsForComparison);
      
      if (isDifferent) { /*
        console.log('Departmanlar değiştirildi:');
        console.log('İlk yüklenen departmanlar:', normalizedInitialDepartments);
        console.log('Güncel departmanlar:', currentDepartmentsForComparison); */
        /* localStorage.setItem('departmentChanged', '1'); */
      } else {
      /*  console.log('Departmanlar değişmedi:');
        console.log('İlk yüklenen departmanlar:', normalizedInitialDepartments);
        console.log('Güncel departmanlar:', currentDepartmentsForComparison); */
        /* localStorage.setItem('departmentChanged', '0'); */
      }

      
      const prefix = location.pathname.startsWith('/f') ? 'f' : 'l';
      const newPath = `/${prefix}/${leadId}/ayarlar`;
      console.log('Navigating to:', newPath); 
      navigate(newPath, { replace: true });
    } else {
      setError('Lütfen tüm departmanlar için isim ve KDV değerlerini doldurunuz.');
    }
    
  }

  const handleNameChange = (index, newName) => {
    const updatedDepartments = [...departments];
    updatedDepartments[index].name = toAdvancedTitleCase(newName);
    setDepartments(updatedDepartments);
  };

  const handleKdvChange = (index, newKdv) => {
    const updatedDepartments = [...departments];
    const department = updatedDepartments[index];
    
    const allowedVats = Array.isArray(department.allowedVats)
      ? department.allowedVats
      : department.allowedVats.split(',');

    const formattedVats = allowedVats.map(vat => `%${vat}`);

    if (formattedVats.includes(newKdv)) {
      department.kdv = newKdv;
    } else {
      department.kdv = formattedVats[0] || '%20';
    }

    setDepartments(updatedDepartments);
  };

  const handleAddDepartment = () => {
    const hasInvalidDepartment = departments.some(dept => dept.name.trim() === "" || !dept.kdv.trim() || dept.kdv === "%");

    if (hasInvalidDepartment) {
      setError('Lütfen eksik departman bilgilerini tamamlayınız. Tüm isim ve KDV alanları dolu olmalıdır. Sonrasında tekrar departman tanımlayabilirsiniz.');
    } else {
      const updatedDepartments = departments.map(dept => ({
        ...dept,
        editing: false
      }));

      const newDepartment = {
        name: "",
        kdv: "%20",
        editing: true,
        allowedVats: "20" 
      };

      setDepartments([...updatedDepartments, newDepartment]);
      setSelectedDept(departments.length); 
      setError('');
    }
  };

  const handleDeleteDepartment = (index) => {
    setDepartments(currentDepartments => {
      const newDepartments = currentDepartments.filter((_, i) => i !== index);
      return newDepartments;
    });
    setError('');
  };

  const resetDepartments = () => {
    setDepartments([]);
    setError('');
  }

  useEffect(() => {
    if (error) {
      errorRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [error]); 

  return (
    <div className='page-wrapper container'>
      <div className='page-top'>
        <PageTop 
          backBtnValue="Geri" 
          title="Departman (Kısım)" 
          rightBtnValue="Sıfırla"
          rightBtnOnClick={resetDepartments}
        />
        <div className='page-content'>
        {fullLoading && <div className='loading-full'></div>}
          <div className='departments'>
            {departments.map((department, index) => (
              <SingleDepartment
                key={index}
                order={index + 1}
                name={toAdvancedTitleCase(department.name)}
                kdv={department.kdv}
                onClick={() => handleClick(index)}
                onDeleteClick={() => handleDeleteDepartment(index)}
                onNameChange={(newName) => handleNameChange(index, newName)}
                onKdvChange={(newKdv) => handleKdvChange(index, newKdv)}
                isSelected={selectedDept === index}
                isEditing={department.editing}
                allowedVats={department.allowedVats}
                selfVersion
              />
            ))}
          </div>
          <div className='txt-btn-wrapper'>
            <a onClick={handleAddDepartment} className='blue-txt-btn'>
              Yeni Departman Tanımla
            </a>
          </div>
          {showWarning && (
            <div className='text-center mt-3'>
              <p className='text-sm mt-2'>
                Tavsiye edilen değerleri lütfen kontrol ediniz. Hatalı KDV oran girişleri ile ilgili Hugin sorumluluk kabul etmemektedir.
              </p>
            </div>
          )}
          {error && <div className='error-message' ref={errorRef}>{error}</div>}
        </div>
      </div>

      <div className='page-bottom'>
        <div className='text-center'>
          <img src={posCihazi} className='img-fluid device-img'/>
        </div>
        <div className='btn-wrapper'>
          <Button value="Devam" viewType="btn-blue" onClick={handleNavigate} />
        </div>
      </div>
    </div>
  );
};

export default SelfDepartments;