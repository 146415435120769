import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import ProtectedRoute from './components/ProtectedRoute';
import Seller_LoginPage from './pages/01-SELLER/01-LGN01';
import Seller_TaxNumberPage from './pages/01-SELLER/01-CRM-KUR01';
import Seller_VoucherInformationsPage from './pages/01-SELLER/01-CRM-KUR02';
import Seller_AuthorizedInformationPage from './pages/01-SELLER/01-CRM-KUR03';
import Seller_DepartmentsPage from './pages/01-SELLER/01-CRM-KUR04';
import Seller_MyDevicesPage from './pages/01-SELLER/01-CRM-KUR05';
import Seller_CashRegisters from './pages/01-SELLER/01-CRM-KUR05V2';
import Seller_DeviceToBeInstalledPage from './pages/01-SELLER/02-CRM-KUR06';
import Seller_BillingInformationsPage from './pages/01-SELLER/01-MUS05';
import Seller_InstallationReportPage from './pages/01-SELLER/02-CRM-KUR07';
import Seller_SalesInquiryReportPage from './pages/01-SELLER/02-CRM-KUR08';
import Seller_SalesInquiryReportPageAlternative from './pages/01-SELLER/02-CRM-KUR09';
import Seller_BillingInformations from './pages/01-SELLER/01-MUS05';

import ErrorPage from './pages/404';
import ProtectedRouteSelf from './components/ProtectedRouteSelf';
import User_Splash from './pages/02-USERS/02-SPLASH';
import User_LoginPage from './pages/02-USERS/01-LGN01';
import User_VoucherInformationsPage from './pages/02-USERS/01-CRM-KUR02';
import User_DepartmentsPage from './pages/02-USERS/01-CRM-KUR04';
import User_InstallationReportPage from './pages/02-USERS/02-CRM-KUR07';
import User_SalesInquiryReportPage from './pages/02-USERS/02-CRM-KUR08';
import User_CargoTracking from './pages/02-USERS/02-CRM-KUR09';
import User_Success from './pages/02-USERS/02-CRM-KUR09_ALT';

import api from './services/api';
import { decryptPassword } from './utils/hash';

console.log(process.env)

function use100vh() {
  const setFullHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    setFullHeight();
    window.addEventListener('resize', setFullHeight);
    return () => window.removeEventListener('resize', setFullHeight);
  }, []);

  return;
}

function AppContent() {
  const [devices, setDevices] = useState([]);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [clickedDevices, setClickedDevices] = useState(() => {
    const storedDevices = localStorage.getItem('clickedDevices');
    return storedDevices ? JSON.parse(storedDevices) : [];
  });

  const location = useLocation();
  const navigate = useNavigate();

  const refreshSession = async () => {
    const username = localStorage.getItem('username');
    const encryptedPassword = localStorage.getItem('password');
    
      if (username && encryptedPassword) {
        try {
          const password = decryptPassword(encryptedPassword);
          const response = await api.login ({
                user_name: username,
                password: password
            });

            if (response && response.error && response.error.number === '10') {
                console.log('Hata', response.error.message);
                localStorage.removeItem('userId');
            } else if (response && response.error && response.error.number === '0') {
                localStorage.setItem('userId', response.id);
                console.log('Session yenilendi');
            }
        } catch (error) {
            console.log('Session yenilenirken hata', error.message);
            localStorage.removeItem('userId');
        }
    } else {
        localStorage.removeItem('userId');
    }
  }

  use100vh();

  useEffect(() => {
    const intervalId = setInterval(refreshSession, 1000 * 60 * 20); // 20 dk
    return () => clearInterval(intervalId); 
  }, []);

  useEffect(() => {
    const handleSelfIdFromUrl = () => {
      const path = location.pathname;
      const idMatch = path.match(/^\/(l|f)\/([^/]+)/);
      if (idMatch) {
        const selfId = idMatch[2];
        localStorage.setItem('selfId', selfId);
      } else {
        localStorage.removeItem('selfId');
      }
    };

    handleSelfIdFromUrl();
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Seller_LoginPage />} />
      <Route 
        path="/okc-satis-sorgulama" 
        element={<ProtectedRoute element={<Seller_TaxNumberPage />} />} 
      />
      <Route path="/fis-basligi-bilgileri" element={<ProtectedRoute element={<Seller_VoucherInformationsPage />} />} />
      <Route path="/firma-yetkili-bilgileri" element={<ProtectedRoute element={<Seller_AuthorizedInformationPage />} />} />
      <Route path="/departmanlar" element={<ProtectedRoute element={<Seller_DepartmentsPage />} />} />
      <Route path="/cihazlarim" element={<ProtectedRoute element={<Seller_MyDevicesPage />} />} />
      <Route path="/yazar-kasalar" element={<ProtectedRoute element={<Seller_CashRegisters />} />} />
      <Route path="/kurulacak-cihazlar" element={<ProtectedRoute element={<Seller_DeviceToBeInstalledPage />} />} />
      <Route path="/fatura-bilgileri" element={<ProtectedRoute element={<Seller_BillingInformationsPage />} />} />
      <Route path="/kurulum-raporu" element={<ProtectedRoute element={<Seller_InstallationReportPage />} />} />
      <Route path="/satis-sorgu-raporu" element={<ProtectedRoute element={<Seller_SalesInquiryReportPage />} />} />
      {/* <Route path="/satis-sorgu-raporu" element={<ProtectedRoute element={<SalesInquiryReportPageAlternative />} />} /> */}
      {/*<Route path="/l" element={<ProtectedRoute element={() => <User_Splash />} />} />
      <Route path="/l/:id" element={<ProtectedRoute element={() => <User_Splash />} />} />
      <Route path="/l/:id/*" element={<ProtectedRoute element={() => <User_Splash />} />} />
      <Route path="/l/:id/fis-basligi-bilgileri" element={<ProtectedRoute element={() => <User_VoucherInformationsPage />} />} />
      <Route path="/l/:id/departmanlar" element={<ProtectedRoute element={() => <User_DepartmentsPage />} />} />
      <Route path="/l/:id/kurulum-raporu" element={<ProtectedRoute element={() => <User_InstallationReportPage />} />} />
      <Route path="/l/:id/satis-sorgu-raporu" element={<ProtectedRoute element={() => <User_SalesInquiryReportPage />} />} />
      <Route path="/l/:id/kargo-takip" element={<ProtectedRoute element={() => <User_CargoTracking />} />} />*/}
      <Route path="/giris-yap" element={<User_LoginPage />} />
      {/* <Route path="/f/:id" element={<ProtectedRouteSelf element={User_Splash} />} /> */}
      <Route path="/f/:id" element={<ProtectedRouteSelf element={User_VoucherInformationsPage} />} />
      <Route path="/f/:id/fis-basligi-bilgileri" element={<ProtectedRouteSelf element={User_VoucherInformationsPage} />} />
      <Route path="/f/:id/departmanlar" element={<ProtectedRouteSelf element={User_DepartmentsPage} />} />
      <Route path="/f/:id/ayarlar" element={<ProtectedRouteSelf element={User_InstallationReportPage} />} />
      <Route path="/f/:id/satis-sorgu-raporu" element={<ProtectedRouteSelf element={User_SalesInquiryReportPage} />} />
      <Route path="/f/:id/kargo-takip" element={<ProtectedRouteSelf element={User_CargoTracking} />} />
      <Route path="/f/:id/basarili" element={<ProtectedRouteSelf element={User_Success} />} />
      <Route path="/f/:id/*" element={<ProtectedRouteSelf element={User_Splash} />} />
      
      <Route path="/404" element={<ErrorPage />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
