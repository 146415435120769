import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ element: Element }) => {
  const isAuthenticated = localStorage.getItem('userId') !== null;
  const location = useLocation();
  const path = location.pathname;

  if (path.startsWith('/f')) {
    const selfId = path.split('/')[2];
    localStorage.setItem('selfId', selfId);
    
    if (!selfId || !selfId.startsWith('FT-')) {
      return <Navigate to="/404" replace />;
    }

    if (!isAuthenticated) {
      return <Navigate to="/giris-yap" state={{ from: location, selfId: selfId }} replace />;
    }
  }

  if (!isAuthenticated) {
    return <Navigate to="/giris-yap" state={{ from: location }} replace />;
  }

  return <Element />;
};

export default ProtectedRoute;