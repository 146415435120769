import React, {useState, useEffect} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import helloImg from '../../../assets/images/hello_img.png';
import hugin from '../../../assets/images/hugin.png';
import Button from '../../../components/Button';
import apiSelf from '../../../services/api-self';
import { decryptPassword } from '../../../utils/hash';

function User_Splash() {
    const { id } = useParams();
    const location = useLocation();
    const [error, setError] = useState('');
    const [userName, setUserName] = useState('');
    const [selfData, setSelfData] = useState('');
    const [btnDisabled, setBtnDisabled] = useState(true);

    const leadId = id;
    localStorage.setItem('leadId', leadId);
    const session = localStorage.getItem('userId');

    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname.startsWith('/f')) {
          navigate(`/f/${leadId}/fis-basligi-bilgileri`, { replace: true });
        }
      }, [location, navigate, leadId]);
    

    const handleNavigate = async () => {
        localStorage.removeItem('selfInvoiceTime');
        localStorage.removeItem('selfDepartments');

        const prefix = location.pathname.startsWith('/f') ? 'f' : 'l';
        console.log('prefix', prefix)
        navigate(`/${prefix}/${leadId}/fis-basligi-bilgileri`);
    }
        
    const refreshSession = async () => {
        const username = localStorage.getItem('username');
        const encryptedPassword = localStorage.getItem('password');
            if((username.length === 0 || username.length === null) || !(encryptedPassword.length === 0 || encryptedPassword.length === null)){
                navigate('/');
            }
        if (username && encryptedPassword) {
            try {
            const password = decryptPassword(encryptedPassword);
            const response = await apiSelf.login ({
                    user_name: username,
                    password: password
                });
                if (response && response.error && response.error.number === '10') {
                    console.log('Hata', response.error.message);
                    localStorage.removeItem('userId');
                } else if (response && response.error && response.error.number === '0') {
                    localStorage.setItem('userId', response.id);
                    console.log('Session yenilendi');
                }
            } catch (error) {
                console.log('Session yenilenirken hata', error.message);
                localStorage.removeItem('userId');
            }
        } else {
            localStorage.removeItem('userId');
        }
    }
    
    const fetchUserInfo = async () => {
        if (session && leadId) {
            setBtnDisabled(false);
            try {
                const userInfo = await apiSelf.get_user_info({
                    session: session,
                    page: 1,
                    limit: 10
                });
                if (userInfo.error && userInfo.error.number === '10') { //invalid loginse
                    await refreshSession(); 
                } else {
                    console.log('error', userInfo.error);
                }
            } catch (error) {
                console.log('Error fetching user info:', error);
            }
        } else {
            setBtnDisabled(true);
        }
    };

    const fetchLogoDetail = async () => {
        setError('');
        const lead_encoded_id = leadId;
        if (session && lead_encoded_id) {
            try {
                const response = await apiSelf.get_logo_detail({
                    session: session,
                    lead_encoded_id: lead_encoded_id
                });
                console.log('get_logo_detail', response);
                if(response.error && response.data.length === 0){
                    setError('Bir hata oluştu');
                    console.log('error', response.error);
                    if (response.error.number === '10') { //invalid loginse
                        await refreshSession(); 
                        await fetchLogoDetail();
                    } else if(response.error.number === '51'){
                        setError('Kayıt bulunmadı.');
                    } else {
                        setError('logo detay' + ' ' + response.error.description);
                    }
                } else {
                    localStorage.setItem('selfData', JSON.stringify(response.data));
                    setSelfData(selfData);
                    setUserName(`${response.data.logo_line_1}`);
                    setBtnDisabled(false);
                }

            } catch (error) {
                console.error('api error:', error);
            }
        }
    };

  /*  useEffect(() => {
        fetchUserInfo();
        fetchLogoDetail();
    }, [leadId, session]); */
    
    return (
        <div className='page-wrapper container welcome'>
            <div className='page-top'>
                <div className={`page-inf-wrapper justify-content-center`}>
                    <div className='text-center'>
                        <h5 className="page-title">
                            Merhaba {userName} 👋
                        </h5>
                    </div>
                </div>
                <div className='page-content'>
                    <div className='logo-wrapper'>
                        <img src={helloImg} className="welcome-img" alt="Hoşgeldiniz" />
                    </div>
                    <h6 className='welcome-txt-1'>
                        Yazarkasa POS’unuzu <br /> hazırlıyoruz.
                    </h6>
                    <h6 className='welcome-txt-2'>
                        Ayarları gözden geçirip onaylamanız sonrasında sizle kargo takip numarasını paylaşabileceğiz.
                    </h6>
                    <div className='btn-wrapper'>
                        <Button 
                            value="Yazarkasa POS’umu Özelleştir" 
                            viewType="btn-blue"
                            onClick={handleNavigate} 
                            disabled={error && error.length > 0}
                        />
                        { error && (
                            <p className='error-message mt-3'>{error}</p>
                        )}
                    </div>
                </div>
            </div>
            <div className='page-bottom'>
                <div className='logo-wrapper pt-0 pb-3'>
                    <img src={hugin} className="logo bottom-logo" alt="Hugin" />
                </div>
            </div>
        </div>
    );
}

export default User_Splash;
