import React from 'react';
import './style.css';

const Button = ({ value, onClick, viewType, disabled }) => {

  return (
      <button 
        onClick={onClick}  
        className={`${viewType} btn`} 
        disabled={disabled}
      >
        { value }
    </button>
  );
};

export default Button;
