import React from 'react';
import error from '../assets/icons/404.png';


const ErrorPage = () => {
    
    return (
        <div className='page-wrapper container h-100vh d-flex justify-content-center align-items-center'>
            <div className='page-bottom h-100 text-center mt-5'>
                <img src={error} className='img-fluid'/>
            </div>
        </div>
    );
}

export default ErrorPage;
