import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import ToBeInstalledItem from '../../../components/DeviceToBeInstalled';
import PageTop from '../../../components/PageTop';



const DeviceToBeInstalled = () => {
  const [error, setError] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const getStoredDevices = () => {
    const clickedDevices = localStorage.getItem('clickedDevices');
    const clickedCashRegisters = localStorage.getItem('clickedCashRegisters');
    if (clickedDevices) {
      return JSON.parse(clickedDevices);
    } else if (clickedCashRegisters) {
      return JSON.parse(clickedCashRegisters);
    }
    return [];
  };

  const [deviceList, setDeviceList] = useState(getStoredDevices);

  useEffect(() => {
    const { devices } = location.state || {};
    if (devices && devices.length > 0) {
      setDeviceList(devices[0]);
    }
  }, [location.state]);

  useEffect(() => {
    const storedDevices = getStoredDevices();
    setDeviceList(storedDevices);
  }, []);

  const handleNavigate = () => {
    if (deviceList.length > 0) {
      const fiscal_id = deviceList.map(device => device.name);
      localStorage.setItem('fiscal_id', JSON.stringify(fiscal_id));

      if (localStorage.getItem('clickedDevices')) {
        localStorage.setItem('clickedDevices', JSON.stringify(deviceList));
      } else if (localStorage.getItem('clickedCashRegisters')) {
        localStorage.setItem('clickedCashRegisters', JSON.stringify(deviceList));
      }

      navigate('/fatura-bilgileri');
    } else {
      setError('Kurulum yapmak için lütfen cihaz ekleyin');
    }
  };

  const chooseDevice = () => {
    navigate(-1); 
  };

  const handleDelete = (deviceId) => {
    const updatedDeviceList = deviceList.filter(device => device.id !== deviceId);
    if (localStorage.getItem('clickedDevices')) {
      localStorage.setItem('clickedDevices', JSON.stringify(updatedDeviceList));
    } else if (localStorage.getItem('clickedCashRegisters')) {
      localStorage.setItem('clickedCashRegisters', JSON.stringify(updatedDeviceList));
    }
    setDeviceList(updatedDeviceList);
  };

  const deleteAllDevice = () => {
    setDeviceList([]);
    if (localStorage.getItem('clickedDevices')) {
      localStorage.setItem('clickedDevices', JSON.stringify([]));
    } else if (localStorage.getItem('clickedCashRegisters')) {
      localStorage.setItem('clickedCashRegisters', JSON.stringify([]));
    }
  };

  return (
    <div className='page-wrapper container'>
      <div className='page-top long-right-btn'>
        <PageTop 
          backBtnValue={"Geri"}
          title={"Sicil Onay"}
          rightBtnValue="Tümünü sil"
          rightBtnOnClick={deleteAllDevice}
        />
        <div className='page-content'>
          <div className='devices'>
            {error && <div className='error-message'>{error}</div>}
            {deviceList.length > 0 ? (
              deviceList.map(device => (
                <ToBeInstalledItem
                  key={device.id}
                  deviceNumber={device.name}
                  onDelete={() => handleDelete(device.id)}
                />
              ))
            ) : (
              <div className='text-center'>
                <div className='txt-btn-wrapper mt-2'>
                  <a
                    onClick={chooseDevice}
                    className='blue-txt-btn'
                  >
                    Cihaz Ekle
                  </a>
                </div>
              </div>
            )}
          </div>
          {deviceList.length > 0 ? <p className='text-md mt-3 text-center'>Seçmiş olduğunuz cihazların sicil numaralarını kontrol edin.</p> : ''}
        </div>
      </div>
      <div className='page-bottom'>
        <div className='btn-wrapper'>
          <Button value="Devam" viewType="btn-blue" onClick={handleNavigate} />
        </div>
      </div>
    </div>
  );
};
export default DeviceToBeInstalled;
