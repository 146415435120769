    import React, { useState, useEffect } from 'react';
    import { useNavigate } from 'react-router-dom';
    import Input from '../../../components/Input/index.js';
    import DateInput from '../../../components/DateInput/index.js';
    import Button from '../../../components/Button/index.js';
    import PageTop from '../../../components/PageTop/index.js';
    import { turkishToUpperCase } from '../../../utils/function.js';
    import { format, parseISO } from 'date-fns'; 


    const BillingInformations = () => {
        const [billingSerialNo, setBillingSerialNo] = useState('');
        const [billingOrderNo, setBillingOrderNo] = useState('');
        const [date, setDate] = useState(null);
        const [billingSerialNoIsValid, setBillingSerialNoIsValid] = useState(false);
        const [errorMessage, setErrorMessage] = useState('');

        const navigate = useNavigate();


        useEffect(() => {
            const storedBillingOrderNo = localStorage.getItem('billingOrderNo');
            const storedBillingSerialNo = localStorage.getItem('billingSerialNo');
            
            if (storedBillingOrderNo) {
              setBillingOrderNo(storedBillingOrderNo);
            }
            
            if (storedBillingSerialNo) {
                setBillingSerialNo(storedBillingSerialNo);
            }
        }, []);

          
        const handleNavigate = async () => {
            if(date != null && billingSerialNo.length > 0 && billingOrderNo.length > 0){
                setErrorMessage('');
                const formattedDate = format(date, 'yyyy-MM-dd');

                localStorage.setItem('invoice_date', formattedDate);
                localStorage.setItem('invoice_serial_number', billingSerialNo + billingOrderNo);
                localStorage.setItem('billingOrderNo', billingOrderNo);
                localStorage.setItem('billingSerialNo', billingSerialNo);
                navigate('/kurulum-raporu');
            } else {
                setErrorMessage('Lütfen zorunlu alanları doldurunuz.')
            }
        };

        const handleBillingSerialNoChange = (event) => {
            const value = turkishToUpperCase(event.target.value);
            setBillingSerialNo(value);
        };

        const handleBillingOrderNoChange = (event) => {
            const value = turkishToUpperCase(event.target.value);
            setBillingOrderNo(value);
            // setBillingSerialNoIsValid(value.length >= 5); 
        };
        
        const handleClearInputSerial = () => {
            setErrorMessage('');
            setBillingSerialNo('');
        };

        const handleClearInputOrder = () => {
            setErrorMessage('');
            setBillingOrderNo('');
        };

        const handleClearInputDate = () => {
            setErrorMessage('');
            setDate(null);
          };

        return (
            <div className='page-wrapper container'>
                <div className='page-top'>
                    <PageTop 
                        backBtnValue={"Geri"}
                        title={"Fatura Bilgileri"}
                    />
                    <div className='page-content'>
                        <h6 className='page-desc page-desc-sm'>
                            Son kullanıcıya düzenlenen fatura numarası ve tarihini giriniz.                        
                        </h6>

                        <Input
                            value={billingSerialNo}
                            input_type={'text'}
                            onChange={handleBillingSerialNoChange}
                            placeholder="Seri Numarası"
                            iconName={'gray-delete'}
                            iconOnClick={handleClearInputSerial}
                        />
                        <Input
                            value={billingOrderNo}
                            input_type={'text'}
                            onChange={handleBillingOrderNoChange}
                            placeholder="Sıra Numarası"
                            iconName={'gray-delete'}
                            iconOnClick={handleClearInputOrder}
                            // input_mode={"numeric"}
                        />
                        <DateInput
                            id="invoice_date"
                            placeholder="GG/AA/YYYY"
                            onChange={setDate} 
                            iconOnClick={handleClearInputDate}
                        />
                        {errorMessage && <div className='error-message'>{errorMessage}</div>}
                    </div>
                </div>
                <div className='page-bottom'>
                    <div className='btn-wrapper'>
                        <Button value="Devam" viewType="btn-blue" onClick={handleNavigate} />
                    </div>
                </div>
            </div>
        );
    }

    export default BillingInformations;
