const api = Object.create({});
api.MAIN_URL = process.env.REACT_APP_MAIN_URL;

api.fetch = async (path, { headers = {}, method, body }) => {
    const login = body ? await fetch(`${api.MAIN_URL}${path}`, {
        headers: { 'content-type': 'application/json', ...headers },
        method,
        body: JSON.stringify(body)
    }) : await fetch(`${api.MAIN_URL}${path}`, {
        headers: { 'content-type': 'application/json', ...headers },
        method
    });
    return await login;
};

// login
api.login = async (_body) => {
    const login = await api.fetch(`/hugin/login`, {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: _body,
    }); 
    const data = await login.json();
    return data;
}

// ecr_setup
api.ecr_setup = async (_body) => {
    const ecr_setup = await api.fetch(`/hugin/ecr_setup`, {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: _body,
    }); 
    const data = await ecr_setup.json();
    return data;
}

// lead_search_by_id
api.lead_search_by_id = async (_body) => {
    const get_data = await api.fetch(`/hugin/lead_search_by_id`, {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: _body,
    }); 
    const data = await get_data.json();
    return data;
}

// lead_list_by_assigned_user
api.lead_list_by_assigned_user = async (_body) => {
    const get_data = await api.fetch(`/hugin/lead_list_by_assigned_user`, {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: _body,
    }); 
    const data = await get_data.json();
    return data;
}

// control_ecr_manuel
api.control_ecr_manuel = async (_body) => {
    const get_data = await api.fetch(`/control_ecr_manuel`, {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: _body,
    }); 
    const data = await get_data.json();
    return data;
}

// get_user_info
api.get_user_info = async (_body) => {
    const get_user_info = await api.fetch(`/hugin/get_user_info`, {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: _body,
    }); 
    const data = await get_user_info.json();
    return data;
}

// get_user_info_manuel
api.get_user_info_manuel = async (_body) => {
    const get_user_info = await api.fetch(`/hugin/get_user_info_manuel`, {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: _body,
    }); 
    const data = await get_user_info.json();
    return data;
}

// get_department_by_nace
api.get_department_by_nace = async (_body) => {
    const get_department_by_nace = await api.fetch(`/hugin/get_department_by_nace`, {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: _body,
    }); 
    const data = await get_department_by_nace.json();
    return data;
}

// get_logo_detail
api.get_logo_detail = async (_body) => {
    const get_logo_detail = await api.fetch(`/hugin/get_logo_detail`, {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: _body,
    }); 
    const data = await get_logo_detail.json();
    return data;
}
// get_logo_detail
api.get_logo_detail_w_id = async (_body) => {
    const get_logo_detail = await api.fetch(`/hugin/get_logo_detail`, {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: _body,
    }); 
    const data = await get_logo_detail.json();
    return data;
}


// set_logo_detail
api.set_logo_detail = async (_body) => {
    const set_logo_detail = await api.fetch(`/hugin/set_logo_detail`, {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: _body,
    }); 
    const data = await set_logo_detail.json();
    return data;
}

// logout
api.logout = async (_body) => {
    const logout = await api.fetch(`/hugin/logout`, {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: _body,
    }); 
    const data = await logout.json();
    return data;
}


// gib okc
api.get_gib_okc = async (_body, vkn, tckn) => {
    let url;
    if(vkn && vkn.length > 0){
        url = `/gib/okc?jp={"mukVergiKimlikNo":"${vkn}"}`; 
    } else if(tckn && tckn.length > 0){
        url = `/gib/okc?jp={"mukTCKimlikNo":"${tckn}"}`;
    }

    const get_gib_okc_info = await api.fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    });
    const data = await get_gib_okc_info.json();
    return data;
}



export default api;