import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import PageTop from '../../../components/PageTop';
import api from '../../../services/api'; 
import { refreshSession, getCleanedValueJSON } from '../../../utils/function.js';

const InstallationReport = () => {
  const [fullLoading, setFullLoading] = useState(false);
  const [accountName, setAccountName] = useState([]);
  const [fullName, setFullName] = useState([]);
  const [mobilePhone, setMobilePhone] = useState('');
  const [shipppingAddress, setShippingAddress] = useState([]);
  const [officePhone, setOfficePhone] = useState([]);
  const [province, setProvince] = useState([]);
  const [billingCountry, setBillingCountry] = useState([]);
  const [taxOffice, setTaxOffice] = useState([]);
  const [taxOfficeCode, setTaxOfficeCode] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [departmentsSetup, setDepartmentsSetup] = useState([]);
  const [fiscalIds, setFiscalIds] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [invoiceTime, setInvoiceTime] = useState('');
  const [logoLines, setLogoLines] = useState([]);
  const [yetkiliBayi, setYetkiliBayi] = useState('');
  const [bayiTelefonu, setBayiTelefonu] = useState('');
  const [naceCode1, setNaceCode1] = useState('');
  const [invoiceDate, setInvoiceDate] = useState('');
  const [invoiceSerialNumber, setInvoiceSerialNumber] = useState('');
  const navigate = useNavigate();

  

  const handleNavigate = async () => {
    let gibAdresBilgileri;
    let onaykodu;

    const gibInfoRaw = localStorage.getItem('gib_info');
    if (gibInfoRaw) {
      const gibInfo = JSON.parse(gibInfoRaw);
      if (gibInfo.adresBilgileri && gibInfo.adresBilgileri.length > 0) {
        const adres = gibInfo.adresBilgileri[0];

        const parts = [
          adres.mahalleSemt,
          // adres.caddeSokak + (adres.disKapiNo ? " No: " + adres.disKapiNo : ""),
          adres.caddeSokak + (adres.disKapiNo ? + adres.disKapiNo : ""),
          adres.icKapiNo ? + adres.icKapiNo : "",
          // adres.icKapiNo ? "İç Kapı No: " + adres.icKapiNo : "",
          adres.beldeBucak,
          adres.koy,
          `${adres.ilceAdi}, ${adres.ilAdi}`
        ].filter(Boolean); 

        gibAdresBilgileri = parts.join(" ");
      }

      console.log('gibinfo', gibInfo.onaykodu);
      if (gibInfo.onaykodu){
        onaykodu = gibInfo.onaykodu;
      }

    }

    setFullLoading(true);
    const userId = localStorage.getItem('userId');
    const full_name = localStorage.getItem('full_name');
    const account_name = localStorage.getItem('account_name');
    const tax_office = localStorage.getItem('tax_office');
    const tax_office_code = localStorage.getItem('tax_office_code');
    const vkn = localStorage.getItem('vkn');
    const tckn = localStorage.getItem('tckn');
    const office_phone = localStorage.getItem('office_phone');
    const mobile_phone = localStorage.getItem('mobile_phone');
    const email = localStorage.getItem('email');
    const nace_code = naceCode1;
    const billing_address = gibAdresBilgileri;
    const billing_county = localStorage.getItem('shipping_county');
    const billing_city = localStorage.getItem('shipping_city');
    const shipping_county = localStorage.getItem('shipping_county');
    const shipping_city = localStorage.getItem('shipping_city');
    const invoice_time = localStorage.getItem('invoice_time');
    const invoiceDate = localStorage.getItem('invoice_date');
    const invoiceSerialNumber = localStorage.getItem('invoice_serial_number');
    const plateCode = localStorage.getItem('plate_code');
    const countryCode = localStorage.getItem('country_code');
    const departmentChanged = localStorage.getItem('departmentChanged');
    
    
    const shipping_address_ls = localStorage.getItem('shipping_address');
    const shipping_address = getCleanedValueJSON(JSON.parse(shipping_address_ls));
    
    const account_legal_name_ls = localStorage.getItem('account_legal_name');
    const account_legal_name = getCleanedValueJSON(JSON.parse(account_legal_name_ls));
    
    const storedFiscalIds = JSON.parse(localStorage.getItem('fiscal_id') || '[]');

    const storedDepartments = JSON.parse(localStorage.getItem('departments') || '[]');
    const departments = {
      department: storedDepartments.map(dept => ({
        name: dept.name,
        rate: dept.kdv
      }))
    };

    const fiscalListForAPI = {
      fiscal_id: storedFiscalIds 
      // fiscal_id: ['FT80010023', 'FT80010046']
    };

    try {
      const response = await api.ecr_setup({
        session: userId,
        full_name: full_name,
        account_name: account_name,
        account_legal_name: account_legal_name,
        tax_office: tax_office,
        vkn: vkn,
        tckn: tckn,
        invoice_date: invoiceDate,
        invoice_serial_number: invoiceSerialNumber,
        office_phone: office_phone,
        mobile_phone: mobile_phone,
        email: email,
        nace_code: nace_code,
        departments: departmentsSetup,
        fiscal_list: fiscalListForAPI,
        billing_address: billing_address,
        billing_county: billing_county,
        billing_city: billing_city,
        shipping_address: shipping_address,
        // shipping_county: countryCode,
        shipping_county: billing_county,
        shipping_city: plateCode,
        logo_lines: logoLines,
        gib_code: onaykodu,
        department_changed: departmentChanged
      });

      if(response.error.number === "0" && response.lead_number){
        localStorage.setItem('ecrResponse', JSON.stringify(response));
        localStorage.setItem('ecr_info', response.ecr_info);
        localStorage.setItem('lead_number', response.lead_number);

        const storedBillingOrderNo = localStorage.getItem('billingOrderNo');
        if (storedBillingOrderNo) {
          if (/^\d+$/.test(storedBillingOrderNo)) {
            const length = storedBillingOrderNo.length;
            const newOrderNo = (parseInt(storedBillingOrderNo, 10) + 1).toString().padStart(length, '0');
            localStorage.setItem('billingOrderNo', newOrderNo);
          } else {
            localStorage.setItem('billingOrderNo', '');
          }
        }
  
        navigate('/satis-sorgu-raporu');
      } else {
        setErrorMessage('Bir hata oluştu. Lütfen bilgilerinizi kontrol edip tekrar deneyin.');
        setFullLoading(false);
        return;
      }
      if(response.error.number === '10'){
        await refreshSession(); 
        return handleNavigate();
      }
    } catch (err) {
      console.error('error', err);
      setFullLoading(false);
    }
    
    setFullLoading(false);
    
  };

  const formatPhoneNumber = (value) => {
    if (!value) return ''; 
    const digits = value.replace(/\D/g, '');
    if (digits.length <= 3) {
      return digits;
    } else if (digits.length <= 6) {
      return `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
    } else {
      return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
    }
  };

  function formatInvoiceDate(invoiceDate) {
    const dateParts = invoiceDate.split('-');
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2].startsWith('0') ? dateParts[2].substring(1) : dateParts[2];
  
    return `${day}.${month}.${year}`;
  }

  useEffect(() => {
    const invoiceDate = localStorage.getItem('invoice_date');
    const invoiceSerialNumber = localStorage.getItem('invoice_serial_number');
    setInvoiceDate(formatInvoiceDate(invoiceDate));
    setInvoiceSerialNumber(invoiceSerialNumber);

    const yetkiliBayi = localStorage.getItem('yetkili_bayi');
    setYetkiliBayi(yetkiliBayi);
    
    const bayiTelefonu = localStorage.getItem('bayi_telefonu');
    setBayiTelefonu(bayiTelefonu);

    const storedLogoLines = localStorage.getItem('logoLines');
    if (storedLogoLines) {
      setLogoLines(JSON.parse(storedLogoLines)); 
      console.log('logoLines', JSON.parse(storedLogoLines));
    }


    const mobile_phone = localStorage.getItem('mobile_phone');
    setMobilePhone(mobile_phone || ''); 

    const account_name = localStorage.getItem('account_name'); 
    setAccountName(account_name || ''); 
  
    const full_name = localStorage.getItem('full_name');
    setFullName(full_name || '');
  
    const shipping_address = localStorage.getItem('shipping_address');
    setShippingAddress(shipping_address || '');
  
    const office_phone = localStorage.getItem('office_phone');
    setOfficePhone(formatPhoneNumber(office_phone) || '');
  
    const shipping_city = localStorage.getItem('shipping_city');
    setProvince(shipping_city || '');
  
    const billing_country = localStorage.getItem('shipping_county');
    setBillingCountry(billing_country || '');

    const tax_office = localStorage.getItem('tax_office');
    setTaxOffice(tax_office || '');

    const tax_office_code = localStorage.getItem('tax_office_code');
    setTaxOfficeCode(tax_office_code || '');

    
    const nace_code = localStorage.getItem('saved_nace_code');
    setNaceCode1(nace_code || '');

    const storedDepartments = localStorage.getItem('departments');
    if (storedDepartments) {
      try {
        const departmentsArray = JSON.parse(storedDepartments);
        if (Array.isArray(departmentsArray)) {
          const filteredDepartments = departmentsArray.map(({ name, kdv }) => ({ name, kdv }));
          setDepartments(filteredDepartments);
        } else {
          console.error('Departman bulunamadı.');
          setDepartments([]); 
        }
      } catch (error) {
        console.error('Local storage\'dan departmanlar ayrıştırılırken hata oluştu:', error);
        setDepartments([]); 
      }
    } else {
      setDepartments([]); 
    }

    


    const storedDepartmentsSetup = localStorage.getItem('departments');
    if (storedDepartmentsSetup) {
      try {
        const departmentsData = JSON.parse(storedDepartmentsSetup);
        if (Array.isArray(departmentsData)) {
          const departmentsArray = departmentsData.map(dept => ({
            name: dept.name,
            rate: dept.kdv.toString() 
          }));
          const wrappedDepartments = { department: departmentsArray };
          setDepartmentsSetup(wrappedDepartments);
        } else {
          console.error('Departman bulunamadı.');
          setDepartmentsSetup([]);
        }
      } catch (error) {
        console.error('Error parsing departments from localStorage:', error);
        setDepartmentsSetup([]); 
      }
    } else {
      setDepartmentsSetup([]); 
    }

    
    const storedFiscalIds = JSON.parse(localStorage.getItem('fiscal_id') || '[]');
    setFiscalIds(storedFiscalIds);

    const invoice_time = localStorage.getItem('invoice_time');
    setInvoiceTime(invoice_time || '');

    
  }, []);

  function formatInvoiceTime(invoiceTimeString) {
    const [datePart, timePart] = invoiceTimeString.split(' ');
  
    const [day, month, year] = datePart.split('-');
  
    return `${day}.${month}.${year}, ${timePart}`;
  }

  return (
    <div className='page-wrapper container'>
      <div className='page-top'>
          <PageTop 
              backBtnValue={"Geri"}
              title={"Kurulum Raporu"}
          />
          <div className='page-content'>
            {fullLoading && <div className='loading-full'></div>}
            <div className='installation-report'>
              <div className='wrapper'>
                <div className='report-top-section'>
                  <div className='row'>
                    <div className='col'>
                      Tarih / Saat :
                    </div>
                    <div className='col text-end'>
                      {formatInvoiceTime(invoiceTime)}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      Yetkili Bayi :
                    </div>
                    <div className='col text-end'>
                      { yetkiliBayi }
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      Bayi Telefonu :
                    </div>
                    <div className='col text-end'>
                      { formatPhoneNumber(bayiTelefonu) }
                    </div>
                  </div>
                </div>
                <hr />
                <div className='text-center infos'>
                    <h6 className='mb-0 mini-title'>Fiş Başlığı</h6>
                    {Object.entries(logoLines).map(([key, value], index, array) => {
                      if (index === array.length - 2) {
                        return (
                          <div key={key} style={{ display: 'flex', justifyContent: 'center', gap: '.5rem' }}>
                            <p className='mb-0'>{value}</p>
                            <p className='mb-0'>{array[index + 1][1]}</p>  
                          </div>
                        );
                      } else if (index < array.length - 2) {
                        return <p className='mb-0' key={key}>{value}</p>;
                      }
                      return null;
                    })}
                </div>
                <hr />

                <div>
                  <h6 className='text-center mini-title'>Departmanlar</h6>
                  {departments.map((department, index) => (
                    <div className='row' key={index}>
                      <div className='col'>
                        {department.name}
                      </div>
                      <div className='col text-end'>
                        KDV %{department.kdv}
                      </div>
                    </div>
                  ))}
                </div>
                <hr />
                <div>
                    <h6 className='text-center mini-title'>ÖKC Sicil No</h6>
                    {fiscalIds.map((fiscalId, index) => (
                      <div className='row' key={index}>
                        <div className='col'>
                          {fiscalId}
                        </div>
                      </div>
                    ))}
                </div>
                <hr />
                <div>
                    <h6 className='text-center mini-title'>Fatura Bilgileri</h6>
                    <div className='row'>
                      <div className='col'>
                        {invoiceSerialNumber}
                      </div>
                      <div className='col text-end'>
                        {invoiceDate}
                      </div>
                    </div>
                </div>
              </div>
            </div>
            {errorMessage && <div className='error-message mt-3'>{errorMessage}</div>}
          </div>
      </div>
      <div className='page-bottom'>
        <div className='btn-wrapper'>
          <Button value="Kurulum kaydı oluştur" viewType="btn-blue" onClick={handleNavigate} />
        </div>
      </div>

    </div>
  );
};

export default InstallationReport;
