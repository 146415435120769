import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '../../../components/Button';
import PageTop from '../../../components/PageTop';
import SignatureCanvas from 'react-signature-canvas';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
import apiSelf from '../../../services/api-self';
import { decryptPassword } from '../../../utils/hash';
import { displayCurrentDateTime } from '../../../utils/function.js';
const baseUrl = process.env.REACT_APP_BASE_URL;

const SalesInquiryReport = () => {
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [leadNumber, setLeadNumber] = useState('');
  const [leadId, setLeadId] = useState('');
  const [fullName, setFullName] = useState('');
  const [taxOffice, setTaxOffice] = useState('');
  const [taxOfficeCode, setTaxOfficeCode] = useState('');
  const [vkn, setVkn] = useState('');
  const [tckn, setTckn] = useState('');
  const [taxType, setTaxType] = useState('');
  const [naceCodes, setNaceCodes] = useState([]);
  const [dateOfStart, setDateOfStart] = useState('');
  const [yetkiliBayi, setYetkiliBayi] = useState('');
  const [accountName, setAccountName] = useState('');
  const [plateCode, setPlateCode] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [onayKodu, setOnayKodu] = useState('');
  const [yetkiliBayiTelefonu, setYetkiliBayiTelefonu] = useState('');
  const [invoiceTime, setInvoiceTime] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [fiscalIds, setFiscalIds] = useState([]);
  const [selfData, setSelfData] = useState('');
  const [logoLines, setLogoLines] = useState('');
  const location = useLocation();

  const navigate = useNavigate();
  const session = localStorage.getItem('userId');
  
  useEffect(() => {
    const lead_id = localStorage.getItem('leadId')
    if(lead_id){
      setLeadId(lead_id);
    }
    const self_data = localStorage.getItem('selfData')
    if(self_data){
      setSelfData(JSON.parse(self_data));
    }
    const new_logo_lines = localStorage.getItem('newLogoLines')
    if(new_logo_lines){
      setLogoLines(JSON.parse(new_logo_lines));
    }
    setInvoiceTime(displayCurrentDateTime());
  }, []);

  
  const refreshSession = async () => {
    const username = localStorage.getItem('username');
    const encryptedPassword = localStorage.getItem('password');
    if (username && encryptedPassword) {
        try {
            const password = decryptPassword(encryptedPassword);
            const response = await apiSelf.login ({
                user_name: username,
                password: password
            });
            if (response && response.error && response.error.number === '10') {
                console.log('Hata', response.error.message);
                localStorage.removeItem('userId');
            } else if (response && response.error && response.error.number === '0') {
                localStorage.setItem('userId', response.id);
                console.log('Session yenilendi');
            }
        } catch (error) {
            console.log('Session yenilenirken hata', error.message);
            localStorage.removeItem('userId');
        }
    } else {
        localStorage.removeItem('userId');
    }
}


  const handleNavigate = async () => {
  /*  const prefix = location.pathname.startsWith('/f') ? 'f' : 'l';
    navigate(`/${prefix}/${leadId}/kargo-takip`); */
  }

  useEffect(() => {
    const handleResize = () => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const sigPad = useRef({});
  // const signClear = () => {
  //   sigPad.current.clear();
  // };
  const signSave = () => {
    const image = sigPad.current.getTrimmedCanvas().toDataURL('image/png');
    console.log(image); 
  };
  
  
  useEffect(() => {
    
  }, []);

  function formatInvoiceTime(invoiceTimeString) {
    const [datePart, timePart] = invoiceTimeString.split(' ');
    const [day, month, year] = datePart.split('-');
    return `${day}.${month}.${year}, ${timePart}`;
  }

  const clearLocalStorage = () => {
    

    navigate('/');
  };
  
  const exportDivToPDF = async (divId) => {
    const element = document.getElementById(divId);

    const opt = {
      margin:       0, 
      filename:     `HGN${leadNumber}.pdf`,
      image:        { type: 'jpeg', quality: 0.5 },
      html2canvas:  { scale: 1 },
      jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
  
    await html2pdf().from(element).set(opt).toPdf().get('pdf').then((pdf) => {
      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setTextColor(0, 0, 255);
        pdf.setFontSize(10);
        pdf.textWithLink(`${onayKodu}`, 20, pdf.internal.pageSize.height - 10, {
          url: `${baseUrl}/index.php?module=Leads&return_module=Leads&action=DetailView&record=${leadId}`
        });
      }
      pdf.save(`${onayKodu}.pdf`);
    });

    clearLocalStorage();
  };

  const isSignaturePresent = (sigPad) => {
    return !sigPad.current.isEmpty();
  };
  
  return (
    <div className='page-wrapper container'>
      <div className='page-top'>
          <PageTop 
              backBtnValue={"Geri"}
              title={"Satış Sorgu Raporu"}
          />
          <div className='page-content'>
            <div id="sales-inquiry-report">
              <div className='sales-inquiry-report'>
                  <h6 className='text-center'>YNÖKC Satış Sorgulama</h6>
                  <div className='report-top-section mb-2'>
                    <div className='row'>
                      <div className='col-5'>
                        <h6 className='mini-title'>Adı Soyadı</h6>
                      </div>
                      <div className='col-7 text-end'>
                        {logoLines.logoLineOne}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-5'>
                        <h6 className='mini-title'>Vergi Dairesi</h6>
                      </div>
                      <div className='col text-end'>
                        {taxOfficeCode} {taxOffice}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-5'>
                        <h6 className='mini-title'>Vergi Kimlik Numarası</h6>
                      </div>
                      <div className='col text-end'>
                        {logoLines.logoLineSeven}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-5'>
                        <h6 className='mini-title'>TCKN</h6>
                      </div>
                      <div className='col text-end'>
                        {tckn}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-5'>
                        <h6 className='mini-title'>Vergi Türü</h6>
                      </div>
                      <div className='col text-end'>
                        {/* 0001 Yıllık gelir vergisi */}
                        {taxType}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-5'>
                        <h6 className='mini-title'>İşe Başlama Tarihi</h6>
                      </div>
                      <div className='col text-end'>
                        {dateOfStart}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-5'>
                        <h6 className='mini-title'>Onay Kodu</h6>
                      </div>
                      <div className='col text-end'>
                        <a target="_blank"  id="approval-link" className="pdf-link" href={`${baseUrl}/index.php?module=Leads&return_module=Leads&action=DetailView&record=${leadId}`}>
                          {onayKodu}
                        </a>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-12'>
                        <h6 className='mini-title'>Faaliyet Kodları</h6>
                      </div>
                      <div className='col-12'>
                        {naceCodes.length > 0 ? (
                          <div>
                            {naceCodes.map((item, index) => (
                              <p className='mb-1' key={index}>{`${item.sira}. ${item.faaliyetKodu} - ${item.faaliyetAdi}`}</p>
                            ))}
                          </div>
                        ) : (
                          <p>Faaliyet kodu bulunamadı.</p>
                        )}
                      </div>
                    </div>
              
                  </div>
                  {errorMessage && <div className='error-message'>{errorMessage}</div>}
                  <h6 className='text-center'>İmza</h6>
                  <div className='sign-area'>
                    <div className='authorized-wrapper'>
                      <span className='txt-sm'>{logoLines.logoLineOne}</span>
                      <span className='txt-sm'>
                        {formatInvoiceTime(invoiceTime)}
                      </span>
                    </div>
                    <SignatureCanvas
                      ref={sigPad}
                      penColor='black'
                      canvasProps={{
                        height: 300, 
                        className: 'sigCanvas'
                      }}
                    />
                    {/* <div className='txt-btn-wrapper mt-1 no-print'>
                      <a
                        onClick={signClear}
                        className='blue-txt-btn'
                      >
                        İmzayı temizle
                      </a>
                    </div> */}
                  </div>  
              </div>
            </div>
            <div className='text-center mt-4 mb-2'>
              <p className='mt-2 text-n'>
                Son olarak mevzuat 
                gereği yukarıdaki bilgileri onayladığınıza dair imzanınızı 
                almamız gerekmektedir.
              </p>
            </div>
          </div>
      </div>
      <div className='page-bottom'>
        <div className='btn-wrapper'>
          {/*
          <Button value="Raporu İndir" viewType="btn-blue" onClick={() => exportDivToPDF('sales-inquiry-report')} />
          */}
          <Button value="Kaydet" viewType="btn-blue" onClick={handleNavigate} />

        </div>
      </div>

    </div>
  );
};

export default SalesInquiryReport;
