import React, { useState, useEffect } from 'react';
import { useNavigate, Navigate, useLocation, useParams } from 'react-router-dom';
import Input from '../../../components/Input/index.js';
import Textarea from '../../../components/Textarea/index.js';
import Button from '../../../components/Button/index.js';
import IconButton from '../../../components/IconButton/index.js';
import PageTop from '../../../components/PageTop/index.js';
import { displayCurrentDateTime } from '../../../utils/function.js';
import { decryptPassword } from '../../../utils/hash.js';
import apiSelf from '../../../services/api-self';
import notFound from '../../../assets/icons/not-found.png';


const SelfVoucherInformations = () => {
  const [error, setError] = useState('');
  const [errorNoRecord, setErrorNoRecord] = useState('');
  const [fullLoading, setFullLoading] = useState(true);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [selfData, setSelfData] = useState('');
  const [invoiceTime, setInvoiceTime] = useState('');
  const [modalDisplay, setModalDisplay] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [logoLinesInput, setLogoLinesInput] = useState({
    logoLineOne: '',
    logoLineTwo: '',
    logoLineThree: '',
    logoLineFour: '',
    logoLineFive: '',
    logoLineSix: '',
    logoLineSeven: ''
  });

  const location = useLocation();
  const navigate = useNavigate();
  const { id: leadId } = useParams();
  const session = localStorage.getItem('userId');
  const selfId = localStorage.getItem('selfId');

    const refreshSession = async () => {
      const username = localStorage.getItem('username');
      const encryptedPassword = localStorage.getItem('password');
      
      if (!username || !encryptedPassword) {
        console.log('Kullanıcı adı veya şifre bulunamadı');
        localStorage.removeItem('userId');
        navigate("/giris-yap", { state: { from: location, selfId: selfId } });
        return null;
      }

      try {
        const password = decryptPassword(encryptedPassword);
        const response = await apiSelf.login({
          user_name: username,
          password: password
        });
        if (response && response.error && response.error.number === '10') {
          console.log('Hata refreshSession', response.error.message);
          localStorage.removeItem('userId');
          return null;
        } else if (response && response.error && response.error.number === '0') {
          localStorage.setItem('userId', response.id);
          console.log('Session yenilendi');
          return response.id;
        }
      } catch (error) {
        console.log('Session yenilenirken hata', error.message);
        localStorage.removeItem('userId');
      }
      
      return null;
    };

    const fetchUserInfo = async () => {
        if (session && leadId) {
            setBtnDisabled(false);
            try {
                const userInfo = await apiSelf.get_user_info({
                    session: session,
                    page: 1,
                    limit: 10
                });
                if (userInfo.error && userInfo.error.number === '10') { //invalid loginse
                    await refreshSession(); 
                } else {
                    console.log('error fetchUserInfo', userInfo.error);
                }
            } catch (error) {
                console.log('Error fetching user info:', error);
            }
        } else {
            setBtnDisabled(true);
        }
    };

    const fetchLogoDetail = async () => {
        setFullLoading(true);
        setError('');
        const lead_encoded_id = leadId;
        let retryCount = 0;

        const fetchData = async () => {
            if (session && lead_encoded_id) {
                try {
                    const response = await apiSelf.get_logo_detail({
                        session: session,
                        lead_encoded_id: lead_encoded_id
                    });
                    if(response.data && response.data.length !== 0){
                        localStorage.setItem('selfData', JSON.stringify(response.data));
                        setSelfData(response.data);
                        setBtnDisabled(false);
                        setLogoLinesInput({
                            logoLineOne: response.data.logo_line_1 || '',
                            logoLineTwo: response.data.logo_line_2 || '',
                            logoLineThree: response.data.logo_line_3 || '',
                            logoLineFour: response.data.logo_line_4 || '',
                            logoLineFive: response.data.logo_line_5 || '',
                            logoLineSix: response.data.logo_line_6 || '',
                            logoLineSeven: response.data.logo_line_7 || ''
                        });
                    } else {
                        setError('Bir hata oluştu');
                        console.log('error fetchLogoDetail', response.error);
                        if (response.error.number === '10') {
                            throw new Error('Invalid session');
                        } else if(response.error.number === '51'){
                          setErrorNoRecord('Kayıt bulunmadı.');
                        } else {
                            setError('logo detay' + ' ' + response.error.description);
                        }
                    }
                } catch (error) {
                    console.error('api error:', error);
                    if (error.message === 'Invalid session' && retryCount < 3) {
                        retryCount++;
                        console.log('Oturum yenileniyor...');
                        const newSession = await refreshSession();
                        if (newSession) {
                            session = newSession;
                            return fetchData();
                        } else {
                            throw new Error('Oturum yenilenemedi');
                        }
                    }
                    throw error;
                }
            }
        };

        try {
            await fetchData();
        } catch (error) {
            console.error('Veri çekme hatası:', error);
            setError('Veri çekilemedi. Lütfen daha sonra tekrar deneyin.');
        } finally {
            setFullLoading(false);
        }
    };


    useEffect(() => {
        fetchUserInfo();
        fetchLogoDetail();
        setInvoiceTime(displayCurrentDateTime());
    }, [leadId, session]);

  const handleInputChange = (e, lineName) => {
    setLogoLinesInput(prev => ({
      ...prev,
      [lineName]: e.target.value,
    }));
  };

  const handleNavigate = () => {
    localStorage.setItem('newLogoLines', JSON.stringify(logoLinesInput));
    localStorage.setItem('selfInvoiceTime', invoiceTime);
    const prefix = location.pathname.startsWith('/f') ? 'f' : 'l';
    navigate(`/${prefix}/${leadId}/departmanlar`);
  };

  const toggleModal = () => {
    setModalDisplay(prev => !prev);
  };


  return (
    <div className='page-wrapper container'>
      {fullLoading && <div className='loading-full'></div>}
      {(selfData) ? (
            <>
              <div className='page-top'>
                <PageTop
                  title={"Fiş Başlığı Bilgileri"}
                />
                <div className='page-content'>
                  {fullLoading && <div className='loading-full'></div>}
                  { modalDisplay === false && (
                    <>
                      <Input
                        maxLength={32}
                        value={logoLinesInput.logoLineOne}
                        displayNonePlaceholder={true}
                        onChange={(e) => handleInputChange(e, 'logoLineOne')}
                        textCenter="true"
                        input_trim
                      />
                      <Input
                        maxLength={32}
                        value={logoLinesInput.logoLineTwo}
                        displayNonePlaceholder={true}
                        onChange={(e) => handleInputChange(e, 'logoLineTwo')}
                        textCenter="true"
                        input_trim
                      />
                      <Input
                        maxLength={32}
                        value={logoLinesInput.logoLineThree}
                        displayNonePlaceholder={true}
                        onChange={(e) => handleInputChange(e, 'logoLineThree')}
                        textCenter="true"
                        input_trim
                      />
                      <Input
                        maxLength={32}
                        value={logoLinesInput.logoLineFour}
                        displayNonePlaceholder={true}
                        onChange={(e) => handleInputChange(e, 'logoLineFour')}
                        textCenter="true"
                        input_trim
                      />
                      <Input
                        maxLength={32}
                        value={logoLinesInput.logoLineFive}
                        displayNonePlaceholder={true}
                        onChange={(e) => handleInputChange(e, 'logoLineFive')}
                        textCenter="true"
                        input_trim
                      />
                      <Textarea
                        value={logoLinesInput.logoLineSix + ' ' + logoLinesInput.logoLineSeven}
                        placeholder="Vergi Dairesi ve Nosu"
                        locked="true"
                        readOnly={true}
                        iconName={'locked'}
                        bgTransparent="true"
                        customClassName="true"
                      />
                        {errorMessage && <div className='error-message'>{errorMessage}</div>}
                      </>
                  )}
                  {modalDisplay && (
                    <div className="invoice-modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-body">
                            {Object.entries(logoLinesInput).map(([key, value], index, array) => {
                              if (index === array.length - 2) {
                                return (
                                  <div key={key} style={{ display: 'flex', justifyContent: 'center', gap: '.5rem' }}>
                                    <p>{value}</p>
                                    <p>{array[index + 1][1]}</p>  
                                  </div>
                                );
                              } else if (index < array.length - 2) {
                                return <p key={key}>{value}</p>;
                              }
                              return null;
                            })}
                            <div className='invoice-info'>
                              <span className='left'>
                                FİŞ NO:0000
                              </span>
                              <span className='right'>
                                {invoiceTime}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='page-bottom'>
                <div className='btn-wrapper'>
                  <Button value="Devam" viewType="btn-blue" onClick={handleNavigate}/>
                  <IconButton
                    iconClass="invoice-icon"
                    viewType="btn-white"
                    onClick={toggleModal}
                    className={modalDisplay ? "pressed" : ""}
                  />
                </div>
              </div>
            </>
        ) : (
            <div className='error-wrapper'>
              { errorNoRecord ? (
                  <>
                    <img src={notFound} className='img-fluid not-found' />
                    <p className='error-message'>{errorNoRecord}</p>
                  </>
                ) : (
                  <p className='error-message'>{error}</p>
                )
                
              }
            </div>
        )

      }

    </div>
  );
};

export default SelfVoucherInformations;
