import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import api from '../../../services/api';
import PageTop from '../../../components/PageTop';
import loginImage from '../../../assets/images/logo.png';
import { encryptPassword } from '../../../utils/hash';

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordIsVisible, setPasswordIsVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();
    const location = useLocation();

    const clearLocalStorage = () => {
        localStorage.removeItem('departments');
        localStorage.removeItem('account_legal_name');
        localStorage.removeItem('vkn');
        localStorage.removeItem('tckn');
        localStorage.removeItem('choosen');
        localStorage.removeItem('tax_office_code');
        localStorage.removeItem('tax_office');
        localStorage.removeItem('clickedDevices');
        localStorage.removeItem('office_phone');
        localStorage.removeItem('fiscal_id');
        localStorage.removeItem('full_name');
        localStorage.removeItem('mobile_phone');
        localStorage.removeItem('email');
        localStorage.removeItem('account_name');
        localStorage.removeItem('shipping_address');
        localStorage.removeItem('shipping_address');
        localStorage.removeItem('shipping_city');
        localStorage.removeItem('shipping_county');
        localStorage.removeItem('gib_info');
        localStorage.removeItem('account_legal_name_short');
        localStorage.removeItem('invoice_time');
        localStorage.removeItem('lead_id');
        localStorage.removeItem('lead_number');
        localStorage.removeItem('legal_name');
        localStorage.removeItem('logoLines');
        localStorage.removeItem('ecr_info');
        localStorage.removeItem('company_type');
        localStorage.removeItem('yetkili_bayi');
        localStorage.removeItem('bayi_telefonu');
        localStorage.removeItem('ecrResponse');
        localStorage.removeItem('saved_nace_code');
        localStorage.removeItem('plate_code');
        localStorage.removeItem('country_code');
      };
      
    

    const handleLogin = async () => {
        setErrorMessage('');
        if (username && password) {
            try {
                const encryptedPassword = encryptPassword(password);
                const body = {
                    'user_name': username,
                    'password': password 
                };
                const loginData = await api.login(body);

                if (loginData && loginData.error && loginData.error.number === '10') {
                    setErrorMessage('Giriş denemesi başarısız oldu, lütfen kullanıcı adını ve şifreyi kontrol edin.');
                } else if (loginData && loginData.error && loginData.error.number === '0') {
                    clearLocalStorage();
                    localStorage.setItem('userId', loginData.id);
                    localStorage.setItem('username', username);
                    localStorage.setItem('password', encryptedPassword);
                    
                    const from = location.state?.from?.pathname || '/okc-satis-sorgulama';
                    navigate(from, { replace: true });
                }
            } catch (error) {
                setErrorMessage('Bir hata oluştu, lütfen daha sonra tekrar deneyiniz.');
                console.log('hata:', error);
            }
        } else {
            setErrorMessage('Lütfen eksik alanları doldurun.');
        }
    };

    const togglePasswordVisibility = () => {
        setPasswordIsVisible(prevState => !prevState);
    };

    return (
        <div className='page-wrapper container'>
            <div className='page-top'>
                <div className='logo-wrapper'>
                    <img src={loginImage} className="logo" alt="Login" />
                </div>
                {process.env.REACT_APP_IS_TEST_ENVIRONMENT === 'true' && (
                    <h5 className='page-title text-center mt-1 fw-500' style={{color: 'var(--blue)'}}>Test Sistemi</h5>
                )}
                <PageTop title={"Kullanıcı Girişi"} />

                <div className='page-content'>
                    <h6 className='page-desc page-desc-n'>
                        Hugin Partner sisteminde kayıtlı kullanıcı bilgilerinizi girin
                    </h6>
                    <Input
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Kullanıcı Adı"
                        autoFocus={true}
                    />
                    <Input
                        value={password}
                        input_type={passwordIsVisible ? 'text' : 'password'}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Şifre"
                        iconName={passwordIsVisible ? 'view-off' : 'view'}
                        iconOnClick={togglePasswordVisibility}
                    />
                    {errorMessage && <div className='error-message'>{errorMessage}</div>}
                </div>
            </div>
            <div className='page-bottom'>
                <div className='btn-wrapper'>
                    <Button value="Devam" viewType="btn-blue" onClick={handleLogin} />
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
