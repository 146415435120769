import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button/index.js';
import SingleCashRegister from '../../../components/SingleCashRegister/index.js';
import PageTop from '../../../components/PageTop/index.js';
import api from '../../../services/api';
import { refreshSession } from '../../../utils/function.js';

const CashRegisters = () => {
  const navigate = useNavigate();
  const [registers, setRegisters] = useState([]);
  const [error, setError] = useState('');
  const [activeInputId, setActiveInputId] = useState(null);
  const [userId, setUserId] = useState('');


  const handleSelectRegister = (id) => {
    setRegisters(registers.map(register => ({
      ...register,
      isSelected: register.id === id
    })));
    setActiveInputId(id);
  };

  const handleNameChange = (id, newName) => {
    const sanitizedNewName = newName.replace(/\s+/g, '');

    const updatedRegisters = registers.map(register => {
      if (register.id === id) {
        return { ...register, name: sanitizedNewName };
      }
      return register;
    });
    setRegisters(updatedRegisters);
  };
  
  useEffect(() => {
    const loadInitialData = async () => {
      let storedRegisters = localStorage.getItem('clickedCashRegisters');
      let formattedRegisters = [];
  
      if (storedRegisters) {
        storedRegisters = JSON.parse(storedRegisters);
        formattedRegisters = storedRegisters.map((item, index) => ({
          ...item,
          id: index + 1,
          isSelected: index === 0,
          isOptional: index !== 0
        }));
      }
  
      const requiredLength = 3;
      while (formattedRegisters.length < requiredLength) {
        formattedRegisters.push({
          id: formattedRegisters.length + 1,
          name: '',
          isSelected: false,
          isOptional: true,
          key: formattedRegisters.length + 1
        });
      }
  
      if (formattedRegisters.length === 0 || formattedRegisters[0].name.trim() === '') {
        formattedRegisters[0].name = '';
        formattedRegisters[0].isSelected = true; 
        formattedRegisters[0].isOptional = false; 
      }
  
      if (formattedRegisters[0].name.trim() !== '') {
        const firstEmptyRegisterIndex = formattedRegisters.findIndex(register => !register.name.trim());
        const focusIndex = firstEmptyRegisterIndex !== -1 ? firstEmptyRegisterIndex : 0;
        setActiveInputId(formattedRegisters[focusIndex].id);
      }
  
      setRegisters(formattedRegisters);
    };
  
    loadInitialData();

    const userId = localStorage.getItem('userId');
    setUserId(userId);
  }, []);
  

  const updatedClickedCashRegisters = async () => {
      const validRegisters = registers.filter(register => register.name.trim() !== '');

      const updatedCashRegisters = validRegisters.map(register => ({
        id: register.id,
        name: register.name.trim(), 
        key: register.key
      }));

      localStorage.setItem('clickedCashRegisters', JSON.stringify(updatedCashRegisters));
  };


  const handleNavigate = async () => {

    if (!registers[0].name.trim()) {
      setError('Birinci yazarkasa sicil numarası boş olamaz.');
      return;
    }

    const validRegisters = registers.filter(register => register.name.trim() !== '');
  
    if (validRegisters.length === 0) {
      setError('Lütfen en az bir yazarkasa sicil numarası giriniz ve birinci yazarkasa numarası boş olamaz.');
      return;
    }

    if (!validRegisters[0].name || !/^F[A-Za-z0-9]{9}$/.test(validRegisters[0].name.trim())) {
      console.log('validRegisters', validRegisters)
      setError('Yazarkasa sicil numarası "F" ile başlamalı ve 10 haneli olmalıdır. Lütfen kontrol ediniz.');
      return;
    }

    const incompleteOrInvalidOtherRegisters = validRegisters.slice(1).some(reg => reg.name && !/^F[A-Za-z0-9]{9}$/.test(reg.name.trim()));
    if (incompleteOrInvalidOtherRegisters) {
      setError('Opsiyonel yazarkasa sicil numaraları tamamen dolu ve doğru formatta olmalı veya tamamen boş bırakılmalıdır.');
      return;
    }

    setError('');

    try {
      const response = await api.control_ecr_manuel({
        session: userId,
        ecr_name_1: validRegisters[0]?.name,
        ecr_name_2: validRegisters[1]?.name || '',
        ecr_name_3: validRegisters[2]?.name || '' 
      });
  
      if(response.error.number === '0'){
        if (response && response.status && response.status === 'true') {
          localStorage.removeItem('clickedCashRegisters');
          localStorage.removeItem('clickedDevices');
          await updatedClickedCashRegisters();
          navigate('/kurulacak-cihazlar');
        } else {
          setError('Verilen sicil numaraları geçersiz. Lütfen kontrol ediniz.');
        }
      } else if(response.error.number === '10'){
        await refreshSession(); 
        return handleNavigate();
      }
      return response;
    } catch (error) {
      setError('Serviste bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.');
      console.error('API Error:', error);
      return null;
    }
    
  };

  const navigateDevices = () => {
    localStorage.removeItem('clickedDevices');
    navigate('/cihazlarim');
  }

  return (
    <div className='page-wrapper container'>
      <div className='page-top'>
        <PageTop
            backBtnValue={"Geri"}
            title={"Sicil Giriş"}
        />
        <div className='page-content'>
          <div className='choose-cash-register-btn' onClick={navigateDevices}>
            <div className='wrapper'>
              <div className='icon search'>
              </div>
              <div className='txt-wrapper'>
                <span className='txt'>Atanmış yazarkasalarımdan seç</span>
              </div>
            </div>
          </div>
          <div className="cash-registers">
            {registers.map((register) => (
              <SingleCashRegister
                key={register.id}
                order={register.id}
                name={register.name}
                isSelected={register.isSelected}
                isOptional={register.isOptional}
                maxLength={10}
                onClick={() => handleSelectRegister(register.id)}
                onNameChange={(newName) => handleNameChange(register.id, newName)}
              />
            ))}
          </div>
          <div className='text-center'>
            <p className='text-sm mt-2'>
              Sicil numarasını cihazın arkasında yer alan sicil etiketinden görüntüleyebilirsiniz.
            </p>
          </div>
          {error && <div className='error-message'>{error}</div>}
        </div>
      </div>

      <div className='page-bottom'>
        <div className='btn-wrapper'>
          <Button value="Devam" viewType="btn-blue" onClick={handleNavigate} />
        </div>
        {/* {showKeypad && <Keypad onInput={handleKeypadInput} />} */}
      </div>
    </div>
  );
};

export default CashRegisters;
