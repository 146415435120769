import React, { useState, useEffect, useRef } from 'react';
import './style.css';

const Input = ({ displayNonePlaceholder, value, onChange, onFocus, id, onBlur, input_mode, input_type, input_trim, placeholder, iconName, iconOnClick, maxLength, hasError, onBeforeInput, readOnly, autoFocus, textCenter, required, optional, locked, bgTransparent }) => {


  const handlePaste = (e) => {
      e.preventDefault();
      const pasteData = e.clipboardData.getData('text/plain');
      onChange({ target: { value: pasteData } });
  };

  const handleChange = (e) => {
    // const newValue = e.target.value.replace(/\s+/g, ' ').trimStart();
    // const newValue = e.target.value.replace(/\s+/g, ' ');
    onChange({ target: { value: e.target.value } });
  };

  const handleChangeWithTrim = (e) => {
    const newValue = e.target.value.replace(/\s+/g, ' ').trimStart();
    onChange({ target: { value: newValue } });
  };

  return (
    <div className={`input-wrapper form-floating mb-2 text-center ${required === 'true' ? 'required' : ''}  ${optional === 'true' ? 'optional' : ''} ${locked === 'true' ? 'locked' : ''}`}>
      <input 
        type={input_type} 
        inputMode={input_mode}
        className={`form-control ${hasError ? 'has-error' : ''} ${displayNonePlaceholder ? 'dNoneTopLabel' : ''} ${textCenter ? 'text-center' : ''} ${bgTransparent === 'true'  ? 'bg-transparent' : ''}`}
        id={id}
        value={value}
        onChange={input_trim == 'true' ? handleChangeWithTrim : handleChange }
        placeholder={placeholder} 
        maxLength={maxLength}
        onBeforeInput={onBeforeInput}
        readOnly={readOnly}
        autoFocus={autoFocus}
        required={required === 'true'} 
        optional = {optional}
        locked={locked}
        onFocus={onFocus}
        aria-label={placeholder}
        onPaste={handlePaste}
        onBlur={onBlur}
      />
      <label htmlFor={id}>
        {placeholder}
      </label>
      {iconName && <div className='input-icon' onClick={iconOnClick}>
        <span className={`${iconName} icon`}></span> 
      </div>}
      {required === 'true' && <div className='required-txt'>
        Zorunlu
      </div>}
      {optional === 'true' && <div className='required-txt'>
        Opsiyonel
      </div>}
    </div>
  );
};

export default Input;
