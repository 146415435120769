import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/success.svg';
import kargo from '../../../assets/images/mng_kargo.png';
const baseUrl = process.env.REACT_APP_BASE_URL;

const SelfSuccess = () => {
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
  
  const navigate = useNavigate();

  useEffect(() => {
    function formatDate(dateString) {
      if (dateString.length !== 8) {
        return 'Invalid date format';
      }
    
      const year = dateString.substring(0, 4);
      const month = dateString.substring(4, 6);
      const day = dateString.substring(6, 8);
    
      return `${month}/${day}/${year}`;
    }
    
    const handleResize = () => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const clearLocalStorage = () => {
    localStorage.removeItem('departments');
    localStorage.removeItem('account_legal_name');
    localStorage.removeItem('vkn');
    localStorage.removeItem('tckn');
    localStorage.removeItem('choosen');
    localStorage.removeItem('tax_office_code');
    localStorage.removeItem('tax_office');
    localStorage.removeItem('clickedDevices');
    localStorage.removeItem('office_phone');
    localStorage.removeItem('fiscal_id');
    localStorage.removeItem('full_name');
    localStorage.removeItem('mobile_phone');
    localStorage.removeItem('email');
    localStorage.removeItem('account_name');
    localStorage.removeItem('shipping_address');
    localStorage.removeItem('shipping_address');
    localStorage.removeItem('shipping_city');
    localStorage.removeItem('shipping_county');
    localStorage.removeItem('gib_info');
    localStorage.removeItem('account_legal_name_short');
    localStorage.removeItem('invoice_time');
    localStorage.removeItem('lead_id');
    localStorage.removeItem('lead_number');
    localStorage.removeItem('legal_name');
    localStorage.removeItem('logoLines');
    localStorage.removeItem('ecr_info');
    localStorage.removeItem('company_type');
    localStorage.removeItem('yetkili_bayi');
    localStorage.removeItem('bayi_telefonu');
    localStorage.removeItem('ecrResponse');
    localStorage.removeItem('invoice_date');
    localStorage.removeItem('invoice_serial_number');
    localStorage.removeItem('plate_code');
    localStorage.removeItem('country_code');
    localStorage.removeItem('saved_nace_code');
    localStorage.removeItem('clickedCashRegisters');

    navigate('/');
  };
  
  useEffect(() => {
      window.history.pushState(null, '', window.location.href);
      window.onpopstate = function() {
          window.history.pushState(null, '', window.location.href);
      };

      return () => {
          window.onpopstate = null;
      };
  }, []);

  
  return (
    <div className='page-wrapper container alternative-page'>
      <div className='page-top'>
          <div className='page-content mt-4' style={{display: 'flex', alignItems: 'center'}}>
            <div id="sales-inquiry-report">
              <div className='success-wrapper mb-5'>
                <SuccessIcon />
              </div>
              <h2 className='info-txt'>Ayarlar kayıt edildi!</h2>
              <p className='text-n'>
                Cihazınız ilk Z raporu sonrasında yeni ayarları ile işlem yapacaktır.
              </p>
            </div>
          </div>
      </div>
      <div className='page-bottom'>
      </div>

    </div>
  );
};

export default SelfSuccess;
