import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/success.svg';
import kargo from '../../../assets/images/mng_kargo.png';
const baseUrl = process.env.REACT_APP_BASE_URL;

const SalesInquiryReport = () => {
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [leadNumber, setLeadNumber] = useState('');
  const [leadId, setLeadId] = useState('');
  const [fullName, setFullName] = useState('');
  const [taxOffice, setTaxOffice] = useState('');
  const [taxOfficeCode, setTaxOfficeCode] = useState('');
  const [taxType, setTaxType] = useState('');
  const [vkn, setVkn] = useState('');
  const [tckn, setTckn] = useState('');
  const [naceCodes, setNaceCodes] = useState([]);
  const [dateOfStart, setDateOfStart] = useState('');
  const [yetkiliBayi, setYetkiliBayi] = useState('');
  const [accountName, setAccountName] = useState('');
  const [plateCode, setPlateCode] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [yetkiliBayiTelefonu, setYetkiliBayiTelefonu] = useState('');
  const [invoiceTime, setInvoiceTime] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [fiscalIds, setFiscalIds] = useState([]);
  const location = useLocation();

  const copyLink = () => {

  }



  const navigate = useNavigate();

  const handleNavigate = () => {
    clearLocalStorage();
    const prefix = location.pathname.startsWith('/f') ? 'f' : 'l';
    navigate(`/${prefix}/${leadId}/kargo-takip`);
  };
  

  useEffect(() => {
    const yetkili_bayi = localStorage.getItem('yetkili_bayi');
    setYetkiliBayi(yetkili_bayi);
    const bayi_telefonu = localStorage.getItem('bayi_telefonu');
    setYetkiliBayiTelefonu(bayi_telefonu);
    const invoice_time = localStorage.getItem('invoice_time');
    setInvoiceTime(invoice_time || '');

    const lead_number = localStorage.getItem('lead_number');
    setLeadNumber(lead_number);
    const lead_id = localStorage.getItem('lead_id');
    setLeadId(lead_id);

    const full_name = localStorage.getItem('full_name');
    setFullName(full_name);

    const tax_office = localStorage.getItem('tax_office');
    setTaxOffice(tax_office);

    const tax_office_code = localStorage.getItem('tax_office_code');
    setTaxOfficeCode(tax_office_code);

    const account_name = localStorage.getItem('account_name');
    setAccountName(account_name);

    const vkn = localStorage.getItem('vkn');
    setVkn(vkn);

    const tckn = localStorage.getItem('tckn');
    setTckn(tckn);

    const gibInfoRaw = localStorage.getItem('gib_info');
    if (gibInfoRaw) {
      const gibInfo = JSON.parse(gibInfoRaw);
      if (gibInfo.nACEFaaliyetKoduveTanimi) {
        setNaceCodes(gibInfo.nACEFaaliyetKoduveTanimi);
      }
      if (gibInfo.iseBaslamaTarihi) {
        setDateOfStart(formatDate(gibInfo.iseBaslamaTarihi));
      }
    }

    
    const plateCode = localStorage.getItem('plate_code');
    setPlateCode(plateCode);
    const countryCode = localStorage.getItem('country_code');
    setPlateCode(countryCode);

    function formatDate(dateString) {
      if (dateString.length !== 8) {
        return 'Invalid date format';
      }
    
      const year = dateString.substring(0, 4);
      const month = dateString.substring(4, 6);
      const day = dateString.substring(6, 8);
    
      return `${month}/${day}/${year}`;
    }
    
    const handleResize = () => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  
  useEffect(() => {
    const ecrInfoRaw = localStorage.getItem('ecrResponse');
    if (ecrInfoRaw) {
      try {
        const ecrInfo = JSON.parse(ecrInfoRaw);
        const lead_id = ecrInfo.lead_id;
        setLeadId(lead_id);
        const items = ecrInfo.ecr_info.item;
        if (!Array.isArray(items)) {
          setFiscalIds([items]);
        } else {
          setFiscalIds(items);
        }
      } catch (error) {
        console.error('hata:', error);
      }
    }
  }, []);


  const clearLocalStorage = () => {
    localStorage.removeItem('departments');
    localStorage.removeItem('account_legal_name');
    localStorage.removeItem('vkn');
    localStorage.removeItem('tckn');
    localStorage.removeItem('choosen');
    localStorage.removeItem('tax_office_code');
    localStorage.removeItem('tax_office');
    localStorage.removeItem('clickedDevices');
    localStorage.removeItem('office_phone');
    localStorage.removeItem('fiscal_id');
    localStorage.removeItem('full_name');
    localStorage.removeItem('mobile_phone');
    localStorage.removeItem('email');
    localStorage.removeItem('account_name');
    localStorage.removeItem('shipping_address');
    localStorage.removeItem('shipping_address');
    localStorage.removeItem('shipping_city');
    localStorage.removeItem('shipping_county');
    localStorage.removeItem('gib_info');
    localStorage.removeItem('account_legal_name_short');
    localStorage.removeItem('invoice_time');
    localStorage.removeItem('lead_id');
    localStorage.removeItem('lead_number');
    localStorage.removeItem('legal_name');
    localStorage.removeItem('logoLines');
    localStorage.removeItem('ecr_info');
    localStorage.removeItem('company_type');
    localStorage.removeItem('yetkili_bayi');
    localStorage.removeItem('bayi_telefonu');
    localStorage.removeItem('ecrResponse');
    localStorage.removeItem('invoice_date');
    localStorage.removeItem('invoice_serial_number');
    localStorage.removeItem('plate_code');
    localStorage.removeItem('country_code');
    localStorage.removeItem('saved_nace_code');
    localStorage.removeItem('clickedCashRegisters');

    navigate('/');
  };
  
  
  return (
    <div className='page-wrapper container alternative-page'>
      <div className='page-top'>
          <div className='page-content mt-4'>
            <div id="sales-inquiry-report">
              <div className='success-wrapper'>
                <SuccessIcon />
              </div>
              <h2 className='info-txt'>Ayarlar kayıt edildi!</h2>
              <p className='text-n'>
                Cihazınız ilk Z raporu sonrasında yeni ayarları ile işlem yapacaktır.
              </p>
              <p className='text-n'>
                Henüz kargonuz size ulaşmadı ise aşağıdaki link üzerinden  takip edebiliriniz.
              </p>
              <div className='link-wrapper'>
                <img src={kargo} className='cargo-icon' />
                <span>
                  MNG KARGO Takip No:
                </span>
                <br/>
                {/* mng kargo sayfasına yönlendir */}
                <p className='cargo-tracking-number'>
                  1291YGH82282
                </p>
                <a onClick={copyLink} className='copy-link'>
                  Kopyala
                </a>
              </div>
            </div>
            <div className='text-center mt-4 mb-2'>
                <p className='fw-700 cong'>
                  Hugin'i tercih ettiğiniz için teşekkürler!
                </p>
            </div>
          </div>
      </div>
      <div className='page-bottom'>
      </div>

    </div>
  );
};

export default SalesInquiryReport;
