    import React, { useState, useEffect, useRef } from 'react';
    import { useNavigate } from 'react-router-dom';
    import Input from '../../../components/Input';
    import Button from '../../../components/Button';
    import Keypad from '../../../components/Keypad';
    import PageTop from '../../../components/PageTop';
    import { toLowerCase, validateTCKN } from '../../../utils/function.js';
    import api from '../../../services/api';
    import provincesData from '../../../datas/provinces.json';
    import taxCodes from '../../../datas/tax_codes.json';
    import taxOffices from '../../../datas/tax_offices.json';


    const TaxNumberPage = () => {
        const [vkn, setVkn] = useState('');
        const [vknIsValid, setVknIsValid] = useState(false);
        const [errorMessage, setErrorMessage] = useState('');
        const [showKeypad, setShowKeypad] = useState(true);
        // const [isChecked, setIsChecked] = useState(false);
        const [loading, setLoading] = useState(false);

        const navigate = useNavigate();
        const errorRef = useRef(null);

        async function findTaxTypeName(taxCode) {
            const numericTaxCode = parseInt(taxCode, 10);
            const tax = taxCodes.find(tax => parseInt(tax.tax_code, 10) === numericTaxCode);
            return tax ? tax.tax_name : "Bilinmiyor";
        }

        async function findTaxOfficeName(officeCode) {
            const numericOfficeCode = parseInt(officeCode, 10);
            const office = taxOffices.find(office => parseInt(office.office_code, 10) === numericOfficeCode);
            return office ? office.tax_office : "Bilinmiyor";
        }

        async function transformData(newData) {
            const adresString = newData.data.okcIzniIslem.isyeriadresi;
            let mahalleSemt, ilceAdi, ilAdi, city_code, district_code;
            
            const lastSlashIndex = adresString.lastIndexOf('/');
            const lastSpaceIndex = adresString.lastIndexOf(' ', lastSlashIndex);

            if (lastSlashIndex > lastSpaceIndex) {
                ilAdi = adresString.substring(lastSlashIndex + 1).trim();
                ilceAdi = adresString.substring(lastSpaceIndex, lastSlashIndex).trim();
                mahalleSemt = adresString.substring(0, lastSpaceIndex).trim();
            } else {
                mahalleSemt = adresString.trim();
                ilceAdi = '';
                ilAdi = '';
            }

            const location = await findLocation(ilceAdi, ilAdi);
            city_code = location.city_code;
            district_code = location.district_code;


            const taxOfficeName = await findTaxOfficeName(newData.data.okcIzniIslem.vdkodu);
            const taxTypeName = await findTaxTypeName(newData.data.okcIzniIslem.vergituru);

            const transformed = {
                // durum: {
                //     durumKodu: "1000",
                //     durumKodAciklamasi: "işlem Başarılı.",
                //     hataDetayBilgisi: "",
                //     sonuc: true
                // },
                vkn: newData.data.okcIzniIslem.vergino,
                tckn: newData.data.okcIzniIslem.tcno,
                vergiDairesiAdi: taxOfficeName,
                vergiDairesiKodu: newData.data.okcIzniIslem.vdkodu,
                vergiTuru: newData.data.okcIzniIslem.vergituru,
                vergiTuruAdi: taxTypeName,
                sirketinTuru: newData.data.okcIzniIslem.sirketturu,
                faalTerkDurumu: "",
                nACEFaaliyetKoduveTanimi: newData.data.okcIzniIslem.faaliyetAdlari.map((item, index) => {
                    const parts = item.split(' - ');
                    return {
                        faaliyetAdi: parts[1],
                        faaliyetKodu: parts[0],
                        sira: (index + 1).toString()
                    };
                }),
                adresBilgileri: [{
                    // adresTipi: "2",
                    adresTipi: '',
                    adresTipiAciklamasi: '',
                    mahalleSemt: mahalleSemt,
                    koy: '',
                    caddeSokak: '',
                    disKapiNo: '',
                    icKapiNo: '',
                    beldeBucak: '',
                    ilceAdi: ilceAdi,
                    ilAdi: ilAdi,
                    ilKodu: city_code,
                    ilceKodu: district_code
                }],
                iseBaslamaTarihi: newData.data.okcIzniIslem.isebaslamatarihi,
                isiBirakmaTarihi: "",
                kimlikUnvani: newData.data.okcIzniIslem.adsoyad,
                unvan: newData.data.okcIzniIslem.adsoyad,
                onaykodu: newData.data.okcIzniIslem.onaykodu,
            };
        
            return transformed;
        }


        async function findLocation(ilceAdi, ilAdi) {
            ilceAdi = toLowerCase(ilceAdi);
            ilAdi = toLowerCase(ilAdi);
        
            const location = provincesData.find(location =>
                toLowerCase(location.district_name) === ilceAdi &&
                toLowerCase(location.city_name) === ilAdi
            );
        
            // if (location) {
            //     console.log(`Bulunan Konum: İlçe Kodu - ${location.district_code}, Şehir Kodu - ${location.city_code}`);
            // } else {
            //     console.log('İlçe veya şehir eşleşmesi bulunamadı.');
            // }
        
            return location ? { district_code: location.district_code, city_code: location.city_code } : {};
        }
        // useEffect(() => {
        //     if (errorMessage) {
        //         errorRef.current.scrollIntoView({ behavior: 'smooth' });
        //     }
        // }, [errorMessage]);

        // const handleCheck = (e) => {
        //     setIsChecked(e.target.checked);
        //     setErrorMessage('');
        // };

        useEffect(() => {
            // const username = localStorage.getItem('username');
            const choosen = localStorage.getItem('choosen');
            let vkn = '';
            if (choosen === 'vkn') {
                vkn = localStorage.getItem('vkn') || '';
            } else if (choosen === 'tckn') {
                vkn = localStorage.getItem('tckn') || '';
            } else {
                vkn = localStorage.getItem('tckn') || '';
            }
            setVkn(vkn);
            if(vkn.length === 11){
                const isValid = validateTCKN(vkn);
                setVknIsValid(isValid);
                setErrorMessage(isValid ? '' : 'Geçerli bir TCKN giriniz.');
            } else {

            }
        }, []);

        useEffect(() => {
            const handleKeyDown = (event) => {
                const key = event.key;
                const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete', 'Enter', '.'];
                if (allowedKeys.includes(key)) {
                    document.querySelector(`button[data-key="${key}"]`)?.classList.add('active');
                }
            };

            const handleKeyUp = (event) => {
                const key = event.key;
                const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete', 'Enter', '.'];
                if (allowedKeys.includes(key)) {
                    document.querySelector(`button[data-key="${key}"]`)?.classList.remove('active');
                }
            };

            window.addEventListener('keydown', handleKeyDown);
            window.addEventListener('keyup', handleKeyUp);

            return () => {
                window.removeEventListener('keydown', handleKeyDown);
                window.removeEventListener('keyup', handleKeyUp);
            };
        }, []);

        const handleVknChange = (e) => {
            const newNumber = e.target.value;
            if (newNumber.length <= 11) {
                setVkn(newNumber);
                if (newNumber.length === 10) {
                    setVknIsValid(/^[0-9]{10}$/.test(newNumber));
                    setErrorMessage('');
                } else if (newNumber.length === 11) {
                    const isValid = validateTCKN(newNumber);
                    setVknIsValid(isValid);
                    setErrorMessage(isValid ? '' : 'Geçerli bir TCKN giriniz.');
                } else {
                    setVknIsValid(false);
                    setErrorMessage('');
                }
            }
            setVkn(e.target.value);
        };

        const disableENumber = (e) => {
            const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
            if (!numbers.includes(e.data) || e.inputType === 'insertText' && e.data === '-') {
                e.preventDefault();
            }
        }

        
        
        const removeItems = () => {
            return new Promise((resolve) => {
                localStorage.removeItem('departments');
                localStorage.removeItem('account_legal_name');
                localStorage.removeItem('vkn');
                localStorage.removeItem('tckn');
                localStorage.removeItem('choosen');
                localStorage.removeItem('tax_office_code');
                localStorage.removeItem('tax_office');
                localStorage.removeItem('clickedDevices');
                localStorage.removeItem('office_phone');
                localStorage.removeItem('fiscal_id');
                localStorage.removeItem('full_name');
                localStorage.removeItem('mobile_phone');
                localStorage.removeItem('email');
                localStorage.removeItem('account_name');
                localStorage.removeItem('shipping_address');
                localStorage.removeItem('shipping_city');
                localStorage.removeItem('shipping_county');
                localStorage.removeItem('gib_info');
                localStorage.removeItem('account_legal_name_short');
                localStorage.removeItem('invoice_time');
                localStorage.removeItem('lead_id');
                localStorage.removeItem('lead_number');
                localStorage.removeItem('legal_name');
                localStorage.removeItem('logoLines');
                localStorage.removeItem('ecr_info');
                localStorage.removeItem('company_type');
                localStorage.removeItem('yetkili_bayi');
                localStorage.removeItem('bayi_telefonu');
                localStorage.removeItem('invoice_date');
                localStorage.removeItem('invoice_serial_number');
                localStorage.removeItem('ecrResponse');
                localStorage.removeItem('clickedCashRegisters');
                localStorage.removeItem('initialDepartments');
                localStorage.removeItem('departmentChanged');
                resolve();
            });
        };

        const handleNavigate = async () => {
            setLoading(true);

            await removeItems();

            // if (!isChecked) {
            //     setErrorMessage('İVD sorgu onayı gereklidir.');
            //     setLoading(false);
            //     return;
            // }

            try {
                if(vkn.length === 10){
                    localStorage.setItem('vkn', vkn);
                    localStorage.setItem('tckn', '');
                    localStorage.setItem('choosen', 'vkn');
                    const response =  await api.get_gib_okc({}, vkn, '');
                    // console.log('response', response);
                    // localStorage.setItem('gib_info', JSON.stringify(response)); 

                    if(response.error && response.messages && response.messages[0] && response.messages[0].text){
                        console.log(response.messages[0].text)
                        const error = response.messages[0].text;
                        setErrorMessage(error);
                        setLoading(false);
                        return;
                    }           
                    
                    if(!response.error || !response.fault){
                        console.log('error',response.error)
                        const transformedData = await transformData(response);
                        localStorage.setItem('gib_info', JSON.stringify(transformedData));
                        navigate('/fis-basligi-bilgileri');
                    }

                    if(response.fault){
                        console.log('fault', response.fault);
                        setErrorMessage(`GİB web servislerinde oluşan bir arıza dolayısıyla kurulumunuzu Partner Sistemi'nde gerçekleştirmeniz gerekmektedir.`);
                        setLoading(false);
                        return;
                    }

                    if (!response.fault && !response.error && response.adresBilgileri) {
                        const adres = response.adresBilgileri[0];
                    
                        const hasInvalidData = Object.values(adres).some(value => value === "***");
                    
                        if (hasInvalidData) {
                            setErrorMessage(`GİB web servislerinde oluşan bir arıza dolayısıyla kurulumunuzu Partner Sistemi'nde gerçekleştirmeniz gerekmektedir.`);
                            setLoading(false);
                            return;
                        }
                    }

                    if(response.fault || response.err){
                        if(response.fault && response.faultStatusCode === '401'){
                            setErrorMessage('GİB servislerinde geçici bir sorun nedeniyle şu an hizmet verilememektedir. Lütfen Partner üzerinden kurulumunuzu gerçekleştirin.')
                        } else if(response.fault || response.err) {
                            setErrorMessage(`GİB web servislerinde oluşan bir arıza dolayısıyla kurulumunuzu Partner Sistemi'nde gerçekleştirmeniz gerekmektedir.`);
                        }
                    };
                    

                    if(response.durum){
                        if(response.durum.sonuc == false){
                            setErrorMessage('Geçerli bir TCKN/VKN giriniz.');
                        } else {
                            navigate('/fis-basligi-bilgileri');
                        }
                    }

                } else if (vkn.length === 11){
                    if (vknIsValid) {
                        localStorage.setItem('tckn', vkn);
                        localStorage.setItem('vkn', '');
                        localStorage.setItem('choosen', 'tckn');
                        const response =  await api.get_gib_okc({}, '', vkn);
                        // localStorage.setItem('gib_info', JSON.stringify(response)); 

                        if(response.error && response.messages && response.messages[0] && response.messages[0].text){
                            const error = response.messages[0].text;
                            setErrorMessage(error);
                            setLoading(false);
                            return;
                        }                     

                        if(!response.error){
                            const transformedData =  await transformData(response);
                            localStorage.setItem('gib_info', JSON.stringify(transformedData));
                            navigate('/fis-basligi-bilgileri');
                        }

                        if (!response.fault && !response.error && response.adresBilgileri) {
                            const adres = response.adresBilgileri[0];
                        
                            const hasInvalidData = Object.values(adres).some(value => value === "***");
                        
                            if (hasInvalidData) {
                                setErrorMessage(`GİB web servislerinde oluşan bir arıza dolayısıyla kurulumunuzu Partner Sistemi'nde gerçekleştirmeniz gerekmektedir.`);
                                setLoading(false);
                                return;
                            }
                        }

                        if(response.fault || response.err){
                            if(response.fault && response.faultStatusCode === '401'){
                                setErrorMessage('GİB servislerinde geçici bir sorun nedeniyle şu an hizmet verilememektedir. Lütfen Partner üzerinden kurulumunuzu gerçekleştirin.')
                            } else if(response.fault || response.err) {
                                setErrorMessage(`GİB web servislerinde oluşan bir arıza dolayısıyla kurulumunuzu Partner Sistemi'nde gerçekleştirmeniz gerekmektedir.`);
                            }
                        };
                        
                        if(response.durum){
                            if(response.durum.sonuc == false){
                                setErrorMessage('Geçerli bir TCKN/VKN giriniz.');
                            } else {
                                navigate('/fis-basligi-bilgileri');
                            }
                        }

                    } else{
                        setErrorMessage('Geçerli bir TCKN giriniz.');
                    }
                } else {
                    setErrorMessage('Geçerli bir TCKN/VKN giriniz.');
            }
            } catch (error) {
                console.log(error);
                // setErrorMessage('Bir hata oluştu. Lütfen tekrar deneyin.');
            }
            setLoading(false);
        };

        const handleClearInput = () => {
            setErrorMessage('');
            setVkn('');
        };

        const handleKeypadInput = (value) => {
            if (value === 'C') {
                setVkn('');
            } else if (value === 'x') {
                setVkn(vkn => vkn.slice(0, -1));
                setErrorMessage('');
            } else {
                const newTCKN = vkn + value;
                if (newTCKN.length <= 11) {
                    setVkn(newTCKN);
                    if (newTCKN.length === 11) {
                        const isValid = validateTCKN(newTCKN);
                        setVknIsValid(isValid);
                        setErrorMessage(isValid ? '' : 'Geçerli bir TCKN/VKN giriniz.');
                    }
                } else {
                    setVkn(newTCKN.slice(0, 11));
                }
            }
        };

        useEffect(() => {
            const handleResize = () => {
                setShowKeypad(window.innerWidth <= 768);
            };
        
            handleResize();
        
            window.addEventListener('resize', handleResize);
        
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }, []);

        return (
            <div className='page-wrapper container'>
                <div className='page-top'>
                    <PageTop 
                        backBtnValue={"Çıkış"}
                        title={"OKC Satış Sorgulama"}
                        isBeta
                    />
                    <div className='page-content'>

                        <h6 className='page-desc page-desc-n big mt-4'>
                            Müşterinizin <b>vergi numarasını</b> girin.
                        </h6>
                        <Input
                            value={vkn}
                            input_type={'text'}
                            onChange={handleVknChange}
                            placeholder="VKN/TCKN"
                            maxLength="11"
                            iconName={vknIsValid ? 'check' : 'gray-delete'}
                            iconOnClick={handleClearInput}
                            hasError={!vknIsValid && vkn.length === 11}
                            onBeforeInput={disableENumber}
                            autoFocus={true}
                            // readOnly={true}
                            handlePaste={true}
                            input_mode={"numeric"}
                        />
                        
                        <h6 className='page-desc page-desc-sm'>
                            Limited ve Anonim şirketler için 10 haneli VKN, Şahıs firmaları için 11 haneli TCKN girilmelidir.
                        </h6>
                        {errorMessage && <div className='error-message text-center' ref={errorRef}>{errorMessage}</div>}

                        {/* <div className='form-check-wrapper'>
                            <div className='form-check form-switch'>
                                <label className="form-check-label" for="flexSwitchCheck">
                                    İVD sorgulamasını yaptım.
                                    <br />
                                    Müşterim ÖKC satın alabilir.
                                </label>
                                <input
                                    className={`form-check-input ${isChecked ? "" : "not-checked-class"}`}
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={handleCheck}
                                    id='flexSwitchCheck'
                                />

                            </div>
                            {errorMessage && <div className='error-message' ref={errorRef}>{errorMessage}</div>}
                        </div> */}
                    </div>
                </div>
                <div className='page-bottom'>
                    <div className='btn-wrapper'>
                        {(loading === true) 
                            ? <button className='btn btn-blue text-center'><div className="loader"></div> </button>
                            : <Button value="Devam" viewType="btn-blue" onClick={handleNavigate} />
                        }
                    </div>
                    {showKeypad && <Keypad onInput={handleKeypadInput} />}
                </div>
            </div>
        );
    }

    export default TaxNumberPage;
