import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import PageTop from '../../../components/PageTop';
import SignatureCanvas from 'react-signature-canvas';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
const baseUrl = process.env.REACT_APP_BASE_URL;

const SalesInquiryReport = () => {
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [leadNumber, setLeadNumber] = useState('');
  const [leadId, setLeadId] = useState('');
  const [fullName, setFullName] = useState('');
  const [taxOffice, setTaxOffice] = useState('');
  const [taxOfficeCode, setTaxOfficeCode] = useState('');
  const [vkn, setVkn] = useState('');
  const [tckn, setTckn] = useState('');
  const [taxType, setTaxType] = useState('');
  const [naceCodes, setNaceCodes] = useState([]);
  const [dateOfStart, setDateOfStart] = useState('');
  const [yetkiliBayi, setYetkiliBayi] = useState('');
  const [accountName, setAccountName] = useState('');
  const [plateCode, setPlateCode] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [onayKodu, setOnayKodu] = useState('');
  const [yetkiliBayiTelefonu, setYetkiliBayiTelefonu] = useState('');
  const [invoiceTime, setInvoiceTime] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [fiscalIds, setFiscalIds] = useState([]);


  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate('/okc-satis-sorgulama');
  };

  useEffect(() => {
    const yetkili_bayi = localStorage.getItem('yetkili_bayi');
    setYetkiliBayi(yetkili_bayi);
    const bayi_telefonu = localStorage.getItem('bayi_telefonu');
    setYetkiliBayiTelefonu(bayi_telefonu);
    const invoice_time = localStorage.getItem('invoice_time');
    setInvoiceTime(invoice_time || '');

    const lead_number = localStorage.getItem('lead_number');
    setLeadNumber(lead_number);
    const lead_id = localStorage.getItem('lead_id');
    setLeadId(lead_id);

    const full_name = localStorage.getItem('full_name');
    setFullName(full_name);

    const tax_office = localStorage.getItem('tax_office');
    setTaxOffice(tax_office);

    const tax_office_code = localStorage.getItem('tax_office_code');
    setTaxOfficeCode(tax_office_code);

    const account_name = localStorage.getItem('account_name');
    setAccountName(account_name);

    const vkn = localStorage.getItem('vkn');
    setVkn(vkn);

    const tckn = localStorage.getItem('tckn');
    setTckn(tckn);

    

    const gibInfoRaw = localStorage.getItem('gib_info');
    if (gibInfoRaw) {
      const gibInfo = JSON.parse(gibInfoRaw);
      if (gibInfo.nACEFaaliyetKoduveTanimi) {
        setNaceCodes(gibInfo.nACEFaaliyetKoduveTanimi);
      }
      if (gibInfo.iseBaslamaTarihi) {
        setDateOfStart(formatDate(gibInfo.iseBaslamaTarihi));
      }
      if(gibInfo.vergiTuru){
        setTaxType(gibInfo.vergiTuru +' '+ gibInfo.vergiTuruAdi);
      }
      if(gibInfo.onaykodu){
        setOnayKodu(gibInfo.onaykodu);
      }
    }

    
    const plateCode = localStorage.getItem('plate_code');
    setPlateCode(plateCode);
    const countryCode = localStorage.getItem('country_code');
    setPlateCode(countryCode);

    function formatDate(dateString) {
      if (dateString.length !== 8) {
        return 'Invalid date format';
      }
    
      const year = dateString.substring(0, 4);
      const month = dateString.substring(4, 6);
      const day = dateString.substring(6, 8);
    
      return `${month}/${day}/${year}`;
    }
    
    const handleResize = () => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const sigPad = useRef({});
  // const signClear = () => {
  //   sigPad.current.clear();
  // };
  const signSave = () => {
    const image = sigPad.current.getTrimmedCanvas().toDataURL('image/png');
    console.log(image); 
  };
  
  
  useEffect(() => {
    const ecrInfoRaw = localStorage.getItem('ecrResponse');
    if (ecrInfoRaw) {
      try {
        const ecrInfo = JSON.parse(ecrInfoRaw);
        const lead_id = ecrInfo.lead_id;
        setLeadId(lead_id);
        const items = ecrInfo.ecr_info.item;
        if (!Array.isArray(items)) {
          setFiscalIds([items]);
        } else {
          setFiscalIds(items);
        }
      } catch (error) {
        console.error('hata:', error);
      }
    }
  }, []);

  function formatInvoiceTime(invoiceTimeString) {
    const [datePart, timePart] = invoiceTimeString.split(' ');
  
    const [day, month, year] = datePart.split('-');
  
    return `${day}.${month}.${year}, ${timePart}`;
  }

  const clearLocalStorage = () => {
    localStorage.removeItem('departments');
    localStorage.removeItem('account_legal_name');
    localStorage.removeItem('vkn');
    localStorage.removeItem('tckn');
    localStorage.removeItem('choosen');
    localStorage.removeItem('tax_office_code');
    localStorage.removeItem('tax_office');
    localStorage.removeItem('clickedDevices');
    localStorage.removeItem('office_phone');
    localStorage.removeItem('fiscal_id');
    localStorage.removeItem('full_name');
    localStorage.removeItem('mobile_phone');
    localStorage.removeItem('email');
    localStorage.removeItem('account_name');
    localStorage.removeItem('shipping_address');
    localStorage.removeItem('shipping_address');
    localStorage.removeItem('shipping_city');
    localStorage.removeItem('shipping_county');
    localStorage.removeItem('gib_info');
    localStorage.removeItem('account_legal_name_short');
    localStorage.removeItem('invoice_time');
    localStorage.removeItem('lead_id');
    localStorage.removeItem('lead_number');
    localStorage.removeItem('legal_name');
    localStorage.removeItem('logoLines');
    localStorage.removeItem('ecr_info');
    localStorage.removeItem('company_type');
    localStorage.removeItem('yetkili_bayi');
    localStorage.removeItem('bayi_telefonu');
    localStorage.removeItem('ecrResponse');
    localStorage.removeItem('invoice_date');
    localStorage.removeItem('invoice_serial_number');
    localStorage.removeItem('plate_code');
    localStorage.removeItem('country_code');
    localStorage.removeItem('saved_nace_code');
    localStorage.removeItem('clickedCashRegisters');
    localStorage.removeItem('initialDepartments');
    localStorage.removeItem('departmentChanged');

    navigate('/');
  };
  
  const exportDivToPDF = async (divId) => {
    // if (!isSignaturePresent(sigPad)) {
    //   setErrorMessage('Lütfen imza atın.');
    //   return;
    // } else {
    //   setErrorMessage('');
    // }

    const element = document.getElementById(divId);

    const opt = {
      margin:       0, 
      filename:     `HGN${leadNumber}.pdf`,
      image:        { type: 'jpeg', quality: 0.5 },
      html2canvas:  { scale: 1 },
      jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
  
    await html2pdf().from(element).set(opt).toPdf().get('pdf').then((pdf) => {
      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setTextColor(0, 0, 255);
        pdf.setFontSize(10);
        pdf.textWithLink(`${onayKodu}`, 20, pdf.internal.pageSize.height - 10, {
          url: `${baseUrl}/index.php?module=Leads&return_module=Leads&action=DetailView&record=${leadId}`
        });
      }
      pdf.save(`${onayKodu}.pdf`);
    });

    clearLocalStorage();
  };

  const isSignaturePresent = (sigPad) => {
    return !sigPad.current.isEmpty();
  };
  
  return (
    <div className='page-wrapper container'>
      <div className='page-top'>
          <PageTop 
              backBtnValue={"Geri"}
              title={"Satış Sorgu Raporu"}
          />
          <div className='page-content'>
            <div id="sales-inquiry-report">
              <div className='sales-inquiry-report'>
                  <h6 className='text-center'>YNÖKC Satış Sorgulama</h6>
                  <div className='report-top-section mb-2'>
                    <div className='row'>
                      <div className='col-5'>
                        <h6 className='mini-title'>Adı Soyadı</h6>
                      </div>
                      <div className='col-7 text-end'>
                        {fullName}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-5'>
                        <h6 className='mini-title'>Vergi Dairesi</h6>
                      </div>
                      <div className='col text-end'>
                        {taxOfficeCode} {taxOffice}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-5'>
                        <h6 className='mini-title'>Vergi Kimlik Numarası</h6>
                      </div>
                      <div className='col text-end'>
                        {vkn}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-5'>
                        <h6 className='mini-title'>TCKN</h6>
                      </div>
                      <div className='col text-end'>
                        {tckn}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-5'>
                        <h6 className='mini-title'>Vergi Türü</h6>
                      </div>
                      <div className='col text-end'>
                        {/* 0001 Yıllık gelir vergisi */}
                        {taxType}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-5'>
                        <h6 className='mini-title'>İşe Başlama Tarihi</h6>
                      </div>
                      <div className='col text-end'>
                        {dateOfStart}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-5'>
                        <h6 className='mini-title'>Onay Kodu</h6>
                      </div>
                      <div className='col text-end'>
                        <a target="_blank"  id="approval-link" className="pdf-link" href={`${baseUrl}/index.php?module=Leads&return_module=Leads&action=DetailView&record=${leadId}`}>
                          {onayKodu}
                        </a>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-12'>
                        <h6 className='mini-title'>Faaliyet Kodları</h6>
                      </div>
                      <div className='col-12'>
                        {naceCodes.length > 0 ? (
                          <div>
                            {naceCodes.map((item, index) => (
                              <p className='mb-1' key={index}>{`${item.sira}. ${item.faaliyetKodu} - ${item.faaliyetAdi}`}</p>
                            ))}
                          </div>
                        ) : (
                          <p>Faaliyet kodu bulunamadı.</p>
                        )}
                      </div>
                    </div>
                    <div>
                        <h6 className='mini-title'>ÖKC Sicil No</h6>
                        {fiscalIds.length > 0 ? (
                          fiscalIds.map((fiscalId, index) => (
                            <div key={index} className='fiscal-item'>
                              <span>{fiscalId.name}</span>
                              <span>{fiscalId.pwd}</span>
                            </div>
                          ))
                        ) : (
                          <p>Cihaz bilgisi bulunamadı.</p>
                        )}
                    </div>
                  </div>
                  {errorMessage && <div className='error-message'>{errorMessage}</div>}
                  <h6 className='text-center'>İmza</h6>
                  <div className='sign-area'>
                    <div className='authorized-wrapper'>
                      <span className='txt-sm'>{fullName}</span>
                      <span className='txt-sm'>
                        {formatInvoiceTime(invoiceTime)}
                      </span>
                    </div>
                    <SignatureCanvas
                      ref={sigPad}
                      penColor='black'
                      canvasProps={{
                        height: 300, 
                        className: 'sigCanvas'
                      }}
                    />
                    {/* <div className='txt-btn-wrapper mt-1 no-print'>
                      <a
                        onClick={signClear}
                        className='blue-txt-btn'
                      >
                        İmzayı temizle
                      </a>
                    </div> */}
                  </div>  
              </div>
            </div>
            <div className='text-center mt-4 mb-2'>
              <h5 className='fw-700'>
                Kurulum oluşturuldu
              </h5>
              <p className='text-sm mt-2'>
                İndirilen pdf dosyasının yazdırılarak, ıslak imzalı halinin tarafınızca saklanması kanunen zorunludur.
              </p>
            </div>
          </div>
      </div>
      <div className='page-bottom'>
        <div className='btn-wrapper'>
          <Button value="Raporu İndir" viewType="btn-blue" onClick={() => exportDivToPDF('sales-inquiry-report')} />
        </div>
      </div>

    </div>
  );
};

export default SalesInquiryReport;
